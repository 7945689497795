<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10Z"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M9.99967 6.66699V13.3337" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M7.5 9.16699L10 6.66699L12.5 9.16699"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'lbReferring',
};
</script>

<style scoped></style>
