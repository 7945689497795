<template>
  <v-col class="pa-0 pl-2 sidebar d-flex flex-column" @click.stop>
    <v-list class="ma-0 pa-0">
      <template v-if="!sidebarMini">
        <!--PROJECT-->
        <v-list-item
          v-if="permissionList('useBrief', 'useToolsProfiler')"
          class="rounded-0 pl-2 mt-3 fz-16 text-grey"
          style="min-height: 28px; height: 28px; padding-top: 6px; padding-bottom: 6px;"
          title="PROJECT">
        </v-list-item>
        <v-expansion-panels
          v-if="permission('useBrief')"
          v-model="brefShow"
          accordion
          elevation="0"
          class="nav-accordeon mt-0"
          :ripple="{class: 'text-purple'}"
          :readonly="!selectedTool.selectedDomain">
          <v-expansion-panel>
            <v-expansion-panel-title class="pa-0" style="height: 26px; min-height: 26px">
              <v-list-item
                class="rounded-0 v-list-item--link pl-2"
                to="/brief"
                style="min-height: 26px; height: 26px"
                @click="brefUpdate">
                <span class="font-weight-regular fz-14" v-text="'Brief Analytics'"></span>
              </v-list-item>
              <template v-slot:actions>
                <v-icon size="18">$chevronDown</v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-list class="ma-0 mb-4 pa-0 expand-list">
                <v-list-item
                  v-for="(link, index) in brefList"
                  :key="index + 'subpage' + link.name"
                  class="rounded-0 mb-0 pl-2"
                  style="min-height: 26px; height: 26px"
                  :ripple="{class: 'text-purple'}"
                  :to="link.url">
                  <span style="margin-left: 26px" class="py-0 d-block" :class="{'fz-14': link.url}">
                    {{ link.name }}
                  </span>
                </v-list-item>
                <v-list-item
                  v-if="selectedTool && selectedTool.charts && selectedTool.charts.tableDataFrame"
                  class="rounded-0 mb-0 pl-2"
                  to="/brief/all-info"
                  style="min-height: 26px; height: 26px">
                  <span class="py-0 ml-6 d-block fz-14">
                    {{ 'All info' }}
                  </span>
                </v-list-item>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-list-item
          v-if="permission('useToolsProfiler')"
          class="rounded-0 v-list-item--link pl-2"
          to="/profiler"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}">
          <span class="font-weight-regular fz-14" v-text="'Profiler'"></span>
        </v-list-item>
        <!--MONETIZATION-->
        <v-list-item
          v-if="permission('useToolsMonetization')"
          class="rounded-0 pl-2 mt-3 fz-16 text-grey"
          style="min-height: 28px; height: 28px; padding-top: 6px; padding-bottom: 6px;"
          title="MONETIZATION">
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsMonetization')"
          class="rounded-0 v-list-item--link pl-2"
          to="/monetization/byproject"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}">
          <span class="font-weight-regular fz-14" v-text="'By Project'"></span>
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsMonetization')"
          class="rounded-0 v-list-item--link pl-2"
          to="/monetization/bybrand"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}">
          <span class="font-weight-regular fz-14" v-text="'By Brand'"></span>
        </v-list-item>
        <!--BACKLINKS-->
        <v-list-item
          v-if="permissionList('useToolsSpamChecker', 'useToolsReuseChecker', 'readLinkPbn')"
          class="rounded-0 pl-2 mt-3 fz-16 text-grey"
          style="min-height: 28px; height: 28px; padding-top: 6px; padding-bottom: 6px;"
          title="BACKLINKS">
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsSpamChecker')"
          class="rounded-0 v-list-item--link pl-2 mb-0"
          to="/spam-checker"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}">
          <span class="font-weight-regular fz-14" v-text="'Spam Checker'"></span>
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsReuseChecker')"
          class="rounded-0 v-list-item--link pl-2 mb-0"
          to="/reuse-checker"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{'v-list-item--active': $route.name === 'Reuse Checker'}">
          <span class="font-weight-regular fz-14" v-text="'Reuse Checker'"></span>
        </v-list-item>
        <v-list-item
          v-if="permission('readLinkPbn')"
          class="rounded-0 v-list-item--link pl-2"
          to="/pbn"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{'v-list-item--active': $route.name === 'Link PBN'}">
          <span class="font-weight-regular fz-14" v-text="'Link PBN'"></span>
        </v-list-item>
        <v-list-item
          v-if="permission('readToolsAnchorAnalyzer')"
          class="rounded-0 v-list-item--link pl-2"
          to="/anchor-analyzer"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{'v-list-item--active': $route.name === 'Anchor Analyzer'}">
          <span class="font-weight-regular fz-14" v-text="'Anchor Analyzer'"></span>
        </v-list-item>
        <v-list-item
          v-if="permission('readToolsHeatmap')"
          class="rounded-0 v-list-item--link pl-2"
          to="/heatmap"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{'v-list-item--active': $route.name === 'Heatmap'}">
          <span class="font-weight-regular fz-14" v-text="'Heatmap'"></span>
        </v-list-item>
        <!--DEVELOPMENT-->
        <v-list-item
          v-if="permission('readTechnical')"
          class="rounded-0 pl-2 mt-3 fz-16 text-grey"
          style="min-height: 28px; height: 28px; padding-top: 6px; padding-bottom: 6px;"
          title="DEVELOPMENT">
        </v-list-item>
        <v-list-item
          v-if="permission('readTechnical')"
          class="rounded-0 v-list-item--link pl-2"
          to="/development/logs"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{'v-list-item--active': $route.name === 'Logs'}">
          <span class="font-weight-regular fz-14" v-text="'Logs'"></span>
        </v-list-item>
        <v-list-item
          v-if="permission('readSourceGraphic')"
          class="rounded-0 v-list-item--link pl-2"
          to="/development/balance-dashboard"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{'v-list-item--active': $route.name === 'Balance Dashboard'}">
          <span class="font-weight-regular fz-14" v-text="'Balance Dashboard'"></span>
        </v-list-item>
        <v-list-item
          v-if="permission('readTechnical')"
          class="rounded-0 v-list-item--link pl-2"
          to="/development/feature-flags"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{'v-list-item--active': $route.name === 'Feature Flags'}">
          <span class="font-weight-regular fz-14" v-text="'Feature Flags'"></span>
        </v-list-item>
        <v-list-item
          v-if="permission('readTechnicalUserIssues') && featureIsActive"
          class="rounded-0 v-list-item--link pl-2"
          to="/development/feed-back"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{'v-list-item--active': $route.name === 'Feedback'}">
          <span class="font-weight-regular fz-14" v-text="'Feedback'"></span>
        </v-list-item>
        <!--ORGANIC SEARCH-->
        <v-list-item
          v-if="
            permissionList(
              'useToolsKeywordsChecker',
              'useToolsTopPages',
              'readToolsContentGap',
              'useToolsCrossmap',
              'readOverview',
            )
          "
          class="rounded-0 pl-2 mt-3 fz-16 text-grey"
          style="min-height: 28px; height: 28px; padding-top: 6px; padding-bottom: 6px;"
          title="ORGANIC SEARCH">
        </v-list-item>
        <v-list-item
          v-if="permission('readOverview')"
          class="rounded-0 v-list-item--link pl-2"
          to="/organic-search/overview"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{
            'v-list-item--active': $route.name === 'Overview',
          }">
          <span class="font-weight-regular fz-14" v-text="'Overview'"></span>
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsKeywordsChecker')"
          class="rounded-0 v-list-item--link pl-2"
          to="/organic-search/keywords-checker"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{
            'v-list-item--active': $route.name === 'Keywords Checker',
          }">
          <span class="font-weight-regular fz-14" v-text="'Keywords Checker'"></span>
        </v-list-item>
        <v-list-item
          v-if="permission('useToolsTopPages')"
          class="rounded-0 v-list-item--link pl-2"
          to="/organic-search/top-pages"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{
            'v-list-item--active': $route.name === 'Top Pages',
          }">
          <span class="font-weight-regular fz-14" v-text="'Top Pages'"></span>
        </v-list-item>
        <v-list-item
          v-if="permissionList('readToolsContentGap', 'writeToolsContentGap')"
          class="rounded-0 v-list-item--link pl-2"
          to="/organic-search/content-gap"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{
            'v-list-item--active': $route.name === 'Content Gap',
          }">
          <span class="font-weight-regular fz-14" v-text="'Content Gap'"></span>
        </v-list-item>
        <v-list-item
          v-if="permissionList('useToolsCrossmap')"
          class="rounded-0 v-list-item--link pl-2"
          to="/organic-search/crossmap"
          style="min-height: 26px; height: 26px"
          :ripple="{class: 'text-purple'}"
          :class="{
            'v-list-item--active': $route.name === 'Crossmap',
          }">
          <span class="font-weight-regular fz-14" v-text="'Crossmap'"></span>
        </v-list-item>

        <v-divider class="mb-4 mt-4" />
      </template>
      <!--INSIGHTS-->
      <v-list-item
        v-if="permission('useInsights')"
        class="rounded-0 pl-2"
        to="/"
        style="height: 26px; min-height: 26px"
        :ripple="{class: 'text-purple'}">
        <span class="mr-2 my-auto">
          <v-icon size="18" class="my-auto">$menuInsights</v-icon>
        </span>
        <span class="font-weight-regular fz-14">Insights</span>
      </v-list-item>
      <!--FINANCE-->
      <v-expansion-panels
        v-if="permission('readFinance')"
        v-model="accordeon.accordeonFNShow"
        accordion
        elevation="0"
        class="nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonFN)" :key="i">
          <v-expansion-panel-title class="pa-0" style="height: 26px; min-height: 26px">
            <v-list-item
              class="rounded-0 v-list-item--link pl-2"
              style="height: 26px; min-height: 26px !important"
              :class="{'v-list-item--active': item.name === $route.name}">
              <span class="mr-2 my-auto">
                <v-icon size="18" :icon="item.icon"></v-icon>
              </span>
              <span class="font-weight-regular fz-14" v-text="item.name"></span>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="18">$chevronDown</v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                class="rounded-0 mb-1 pl-2"
                style="height: 26px; min-height: 26px;"
                :to="link.url"
                :ripple="{class: 'text-purple'}">
                <span style="margin-left: 26px;" class="py-0 d-block" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </span>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!--MANAGEMENT-->
      <v-expansion-panels
        v-if="permission('readManagement')"
        v-model="accordeon.accordeonMMShow"
        accordion
        elevation="0"
        class="nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonMM)" :key="i">
          <v-expansion-panel-title class="pa-0" style="height: 26px; min-height: 26px">
            <v-list-item
              class="rounded-0 v-list-item--link pl-2"
              style="height: 26px; min-height: 26px !important"
              :class="{'v-list-item--active': item.name === $route.name}">
              <span class="mr-2 my-auto">
                <v-icon size="18" class="my-auto" :icon="item.icon"></v-icon>
              </span>
              <span class="font-weight-regular fz-14" v-text="item.name"></span>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="18">$chevronDown</v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                class="rounded-0 mb-1 pl-2"
                style="height: 26px; min-height: 26px"
                :to="link.url"
                :ripple="{class: 'text-purple'}">
                <span style="margin-left: 26px" class="py-0 d-block" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </span>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!--LINK BUILDING-->
      <v-expansion-panels
        v-if="permission('readLinkbuilding')"
        v-model="accordeon.accordeonLBShow"
        accordion
        elevation="0"
        class="nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonLB)" :key="i">
          <v-expansion-panel-title class="pa-0" style="height: 26px; min-height: 26px">
            <v-list-item
              class="rounded-0 v-list-item--link pl-2"
              style="height: 26px; min-height: 26px !important"
              :class="{'v-list-item--active': item.name === $route.name}">
              <span class="mr-2 my-auto">
                <v-icon size="18" class="my-auto" :icon="item.icon"></v-icon>
              </span>
              <span class="font-weight-regular fz-14" v-text="item.name"></span>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="18"> $chevronDown</v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                class="rounded-0 mb-1 pl-2"
                style="height: 26px; min-height: 26px;"
                :to="link.url"
                :ripple="{class: 'text-purple'}">
                <span style="margin-left: 26px" class="py-0 d-block" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </span>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!--CONTENT MARKETING-->
      <v-expansion-panels
        v-if="permission('readContentMarketing')"
        v-model="accordeon.accordeonCMShow"
        accordion
        elevation="0"
        class="nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonCM)" :key="i">
          <v-expansion-panel-title class="pa-0" style="height: 26px; min-height: 26px">
            <v-list-item
              class="rounded-0 v-list-item--link pl-2"
              style="height: 26px; min-height: 26px !important"
              :class="{'v-list-item--active': item.name === $route.name}">
              <span class="mr-2 my-auto">
                <v-icon size="18" class="my-auto" :icon="item.icon"></v-icon>
              </span>
              <span class="font-weight-regular fz-14" v-text="item.name"></span>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="18">$chevronDown</v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                class="rounded-0 mb-1 pl-2"
                style="height: 26px; min-height: 26px;"
                :to="link.url"
                :ripple="{class: 'text-purple'}">
                <span style="margin-left: 26px" class="py-0 d-block" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </span>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!--MARKETING PLAN-->
      <v-expansion-panels
        v-if="permission('readMarketingPlan')"
        v-model="accordeon.accordeonMPShow"
        accordion
        elevation="0"
        class="nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonMP)" :key="i">
          <v-expansion-panel-title class="pa-0" style="height: 26px; min-height: 26px">
            <v-list-item
              class="rounded-0 v-list-item--link pl-2"
              style="height: 26px; min-height: 26px !important"
              :class="{'v-list-item--active': item.name === $route.name}">
              <span class="mr-2 my-auto">
                <v-icon size="18" class="my-auto" :icon="item.icon"></v-icon>
              </span>
              <span class="font-weight-regular fz-14" v-text="item.name"></span>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="18">$chevronDown</v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                class="rounded-0 mb-1 pl-2"
                style="height: 26px; min-height: 26px;"
                :to="link.url"
                :ripple="{class: 'text-purple'}">
                <span style="margin-left: 26px;" class="py-0 d-block" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </span>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!--SEMANTICS-->
      <v-list-item
        v-if="permission('readSemantics')"
        class="rounded-0 ma-0 pl-2 mt-2"
        to="/semantics"
        style="height: 26px; min-height: 26px"
        :ripple="{class: 'text-purple'}">
        <span class="mr-2 my-auto">
          <v-icon size="18" class="my-auto" icon="$menuSemantics" />
        </span>
        <span class="font-weight-regular fz-14">Semantics</span>
      </v-list-item>
      <!--ORDERS-->
      <v-expansion-panels
        v-if="permissionList('readFinanceOrders', 'readSuppliers')"
        v-model="accordeon.accordeonOShow"
        accordion
        elevation="0"
        class="nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonO)" :key="i">
          <v-expansion-panel-title class="pa-0" style="height: 26px; min-height: 26px">
            <v-list-item
              class="rounded-0 v-list-item--link pl-2"
              style="height: 26px; min-height: 26px !important"
              :class="{'v-list-item--active': item.name === $route.name}">
              <span class="mr-2 my-auto">
                <v-icon size="18" class="my-auto" :icon="item.icon"></v-icon>
              </span>
              <span class="font-weight-regular fz-14" v-text="item.name"></span>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="18">$chevronDown</v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                class="rounded-0 mb-1 pl-2"
                style="height: 26px; min-height: 26px;"
                :to="link.url"
                :ripple="{class: 'text-purple'}">
                <span style="margin-left: 26px" class="py-0 d-block" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </span>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!--MAINTENANCE-->
      <v-expansion-panels
        v-if="permission('readMaintenance')"
        v-model="accordeon.accordeonMTShow"
        accordion
        elevation="0"
        class="nav-accordeon">
        <v-expansion-panel v-for="(item, i) in filterByPermissions(accordeonMT)" :key="i">
          <v-expansion-panel-title class="pa-0" style="height: 26px; min-height: 26px">
            <v-list-item
              class="rounded-0 v-list-item--link pl-2"
              style="height: 26px; min-height: 26px !important"
              :class="{'v-list-item--active': item.name === $route.name}">
              <span class="mr-2 my-auto">
                <v-icon size="18" class="my-auto" :icon="item.icon"></v-icon>
              </span>
              <span class="font-weight-regular fz-14" v-text="item.name"></span>
            </v-list-item>
            <template v-slot:actions>
              <v-icon size="18">$chevronDown</v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list v-if="item.subpages" class="ma-0 pa-0 expand-list">
              <v-list-item
                v-for="(link, index) in item.subpages"
                :key="index + 'subpage' + link.name"
                class="rounded-0 mb-1 pl-2"
                style="height: unset; min-height: 10px;"
                :to="link?.url"
                :ripple="{class: 'text-purple'}">
                <span style="margin-left: 26px" class="py-0 d-block" :class="{'fz-14': link.url}">
                  {{ link.name }}
                </span>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!--BRAND BOARD-->
      <v-list-item
        v-if="permission('readAffilateMarketing')"
        class="rounded-0 ma-0 pl-2 mt-2"
        to="/brand-board"
        style="height: 26px; min-height: 26px"
        :ripple="{class: 'text-purple'}">
        <span class="mr-2 my-auto">
          <v-icon size="18" class="my-auto" icon="$menuAffiliateMarketing" />
        </span>
        <span class="font-weight-regular fz-14" v-text="'Brand Board'"></span>
      </v-list-item>
      <!--REPORTS-->
      <v-list-item
        v-if="permission('readReports')"
        class="rounded-0 mb-0 pl-2 mt-2"
        to="/reports"
        style="height: 26px; min-height: 16px"
        :ripple="{class: 'text-purple'}">
        <span class="mr-2 my-auto">
          <v-icon size="18" class="my-auto" icon="$menuReports"></v-icon>
        </span>
        <span class="font-weight-regular fz-14" v-text="'Reports'"></span>
      </v-list-item>
      <!--TEAM-->
      <v-list-item
        v-if="permission('readTeam')"
        class="rounded-0 mb-2 pl-2 mt-2"
        to="/team"
        style="height: 26px; min-height: 26px"
        :ripple="{class: 'text-purple'}">
        <span class="mr-2 my-auto">
          <v-icon size="18" class="my-auto" icon="$menuTeam"></v-icon>
        </span>
        <span class="font-weight-regular fz-14" v-text="'Team'"></span>
      </v-list-item>
    </v-list>

    <v-row class="ma-0 flex-grow-1"></v-row>
    <v-sheet v-if="!sidebarMini" color="transparent" elevation="0" class="ma-0 pa-0">
      <v-sheet
        v-if="myBirthday.length"
        class="mx-6 mt-6 mb-0 rounded-3 border-grey px-4 pt-4 pb-0"
        style="box-shadow: 0px 16px 24px rgba(36, 36, 54, 0.05)">
        <v-col class="pa-0">
          <v-img
            width="60"
            height="60"
            max-width="60"
            max-height="60"
            class="mb-4 mx-auto"
            src="@/assets/img/Emoji-large.png"></v-img>
          <p class="fz-14 font-weight-medium text-center">
            Happy Birthday,<br />{{ myBirthday[0].fullName }}
          </p>
        </v-col>
      </v-sheet>
      <v-sheet
        v-else-if="birthday.length === 1"
        class="mx-6 mt-6 mb-0 rounded-3 border-grey px-4 pt-4 pb-0"
        style="box-shadow: 0px 16px 24px rgba(36, 36, 54, 0.05)">
        <v-row class="ma-0">
          <v-img
            width="20"
            height="20"
            max-width="20"
            max-height="20"
            class="mr-3"
            src="@/assets/img/Emoji.png"></v-img>
          <span class="fz-14 font-weight-medium">Birthday today</span>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <v-row class="ma-0">
          <v-col class="pa-0 d-flex flex-column align-center">
            <v-avatar size="60" class="rounded-circle mx-auto mb-3">
              <img v-if="birthday[0].avatar" :src="birthday[0].avatar" alt="" />
              <AvatarsItem v-else :item="birthday[0]" customClass="no-avatar-style_HB" />
            </v-avatar>
            <p class="ma-0 fz-14 lh-5 text-center">
              {{ birthday[0].fullName }}
            </p>
            <p class="ma-0 fz-14 lh-5 text-grey mb-3 text-center">
              {{ agesToday(birthday[0].birthday) }} years old!
            </p>
            <v-row class="ma-0">
              <a target="_blank" :href="'http://t.me/' + birthday[0].telegram.replace('@', '')">
                <v-img width="44" height="44" src="@/assets/img/msg-icon.png"></v-img>
              </a>
              <a target="_blank" :href="'http://t.me/' + birthday[0].telegram.replace('@', '')">
                <v-img width="44" height="44" src="@/assets/img/call-icon.png"></v-img>
              </a>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet
        v-else-if="birthday.length > 1"
        class="mx-6 mt-6 mb-0 rounded-3 border-grey px-4 pt-4 pb-0"
        style="box-shadow: 0px 16px 24px rgba(36, 36, 54, 0.05)">
        <v-row class="ma-0">
          <v-img
            width="20"
            height="20"
            max-width="20"
            max-height="20"
            class="mr-3"
            src="@/assets/img/Emoji.png"></v-img>
          <span class="fz-14 font-weight-medium">Birthday today</span>
        </v-row>
        <v-divider class="my-4" />
        <v-list class="pa-0">
          <v-list-item
            v-for="(item, index) in birthday"
            :key="index"
            class="rounded-0 mb-2 pa-0"
            style="min-height: 28px !important; pointer-events: none">
            <div class="d-flex align-center">
              <span class="mr-3 my-0">
                <v-avatar
                  size="28"
                  class="rounded-circle"
                  :color="item.avatar || item.team ? 'transparent' : 'primary'">
                  <img v-if="item.avatar" :src="item.avatar" alt="" />
                  <AvatarsItem v-else :item="item" customClass="no-avatar-style_Header" />
                </v-avatar>
              </span>
              <div class="fz-12 pa-0">
                <p class="ma-0">{{ item.fullName }}</p>
                <span class="text-grey"> {{ agesToday(item.birthday) }} years old! </span>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-sheet>
    <v-sheet
      v-else-if="sidebarMini && (myBirthday.length || birthday.length)"
      color="transparent"
      elevation="0"
      class="my-0 mx-auto pa-0">
      <v-img width="24" height="24" max-width="24" max-height="24" src="@/assets/img/Emoji.png"></v-img>
    </v-sheet>
    <v-row class="ma-0 flex-grow-0">&nbsp;</v-row>
    <v-dialog
      v-model="birthdayShow"
      content-class="width-unset  elevation-0 rounded-4"
      overlay-color="rgba(57,57,74,0.75)"
      overlay-opacity="1">
      <v-card v-if="birthdayShow && birthday.length > 1" class="relative d-flex align-center">
        <v-icon
          style="position: absolute; right: 16px; top: 16px; z-index: 9999; cursor: pointer"
          @click="closeWindow">
          $close
        </v-icon>
        <v-img width="90" height="379" class="flex-grow-0" src="@/assets/img/bth-bg-left.png"></v-img>
        <v-sheet class="mx-12">
          <p class="fz-30 font-weight-medium lh-3 text-center mb-12">Birthday today!</p>
          <v-row class="ma-0">
            <template v-for="(item, index) in birthday" :key="index + 'modal'">
              <v-divider v-if="index" :key="index + 'modalDivider'" vertical class="mx-9 mb-4"></v-divider>
              <v-col class="pa-0 align-center d-flex flex-column" style="min-width: 135px">
                <v-avatar
                  size="72"
                  class="rounded-4 mb-4"
                  :color="item.avatar || item.team ? 'transparent' : 'primary'">
                  <img v-if="item.avatar" :src="item.avatar" alt="" />
                  <AvatarsItem v-else :item="item" customClass="no-avatar-style_HB" />
                </v-avatar>
                <p class="ma-0 fz-18 font-weight-medium mb-1" v-text="item.fullName"></p>
                <span class="text-grey fz-14"> Today is {{ agesToday(item.birthday) }} years old! </span>
                <v-row class="ma-0 mt-3">
                  <a target="_blank" :href="'http://t.me/' + item.telegram.replace('@', '')">
                    <v-img width="64" height="64" src="@/assets/img/msg-icon.png"></v-img>
                  </a>
                  <a target="_blank" :href="'http://t.me/' + item.telegram.replace('@', '')">
                    <v-img width="64" height="64" src="@/assets/img/call-icon.png"></v-img>
                  </a>
                </v-row>
              </v-col>
            </template>
          </v-row>
        </v-sheet>
        <v-img width="90" height="379" class="flex-grow-0" src="@/assets/img/bth-bg-right.png"></v-img>
      </v-card>
      <v-card v-if="birthdayShow && birthday.length === 1" class="relative d-flex align-center">
        <v-icon
          style="position: absolute; right: 16px; top: 16px; z-index: 9999; cursor: pointer"
          @click="closeWindow">
          $close
        </v-icon>
        <v-img width="90" height="332" class="flex-grow-0" src="@/assets/img/bth-bg-left.png"></v-img>
        <v-sheet class="mx-12">
          <p class="fz-30 font-weight-medium lh-3 text-center mb-7">Birthday today!</p>
          <v-row class="ma-0">
            <v-col class="pa-0 align-center d-flex flex-column" style="min-width: 135px">
              <v-row class="ma-0 mb-7 align-center">
                <a target="_blank" :href="'http://t.me/' + birthday[0].telegram.replace('@', '')">
                  <v-img width="64" height="64" src="@/assets/img/msg-icon.png" class="mr-7 mt-3 mb-n1" />
                </a>
                <v-avatar
                  class="rounded-4"
                  size="72"
                  :color="birthday[0].avatar || birthday[0].team ? 'transparent' : 'primary'">
                  <img v-if="birthday[0].avatar" :src="birthday[0].avatar" alt="" />
                  <AvatarsItem v-else :item="birthday[0]" customClass="no-avatar-style_HB" />
                </v-avatar>
                <a target="_blank" :href="'http://t.me/' + birthday[0].telegram.replace('@', '')">
                  <v-img width="64" height="64" class="ml-7 mt-3 mb-n1" src="@/assets/img/call-icon.png" />
                </a>
              </v-row>
              <p class="ma-0 fz-22 font-weight-medium mb-1" v-text="birthday[0].fullName"></p>
              <span class="text-grey fz-16"> Today is {{ agesToday(birthday[0].birthday) }} years old! </span>
            </v-col>
          </v-row>
        </v-sheet>
        <v-img width="90" height="332" class="flex-grow-0" src="@/assets/img/bth-bg-right.png"></v-img>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="myBirthdayShow"
      content-class="width-unset  elevation-0 rounded-4"
      overlay-color="rgba(57,57,74,0.75)"
      overlay-opacity="1">
      <v-card v-if="myBirthdayShow" class="relative d-flex align-center">
        <v-icon
          style="position: absolute; right: 16px; top: 16px; z-index: 9999; cursor: pointer"
          @click="birthdayCheck">
          $close
        </v-icon>
        <v-img width="90" height="282" class="flex-grow-0" src="@/assets/img/bth-bg-left.png"></v-img>
        <v-sheet class="mx-12">
          <p class="fz-30 font-weight-medium lh-3 text-center mb-0">Happy Birthday To You,</p>
          <p class="fz-30 font-weight-medium lh-3 text-center mb-7">
            {{ myBirthday[0].fullName }}
          </p>
          <v-row class="ma-0 justify-center">
            <v-avatar
              size="72"
              class="rounded-4 mb-4"
              :color="myBirthday[0].avatar || myBirthday[0].team ? 'transparent' : 'primary'">
              <img v-if="myBirthday[0].avatar" :src="myBirthday[0].avatar" alt="" />
              <AvatarsItem v-else :item="myBirthday[0]" customClass="no-avatar-style_HB" />
            </v-avatar>
          </v-row>
        </v-sheet>
        <v-img width="90" height="282" class="flex-grow-0" src="@/assets/img/bth-bg-right.png"></v-img>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="welcomeShowOpen"
      content-class="width-unset  elevation-0 rounded-4"
      overlay-color="rgba(57,57,74,0.75)"
      overlay-opacity="1"
      max-width="700"
      @click:outside="$emit('checkBirthday')">
      <v-card
        v-if="welcomeShow"
        class="relative d-flex align-center flex-column py-12 justify-center"
        style="padding-left: 73px; padding-right: 73px">
        <v-icon
          style="position: absolute; right: 16px; top: 16px; z-index: 9999; cursor: pointer"
          @click="$emit('checkBirthday')">
          $close
        </v-icon>
        <v-sheet class="d-flex justify-center flex-column align-center">
          <p class="fz-30 font-weight-medium lh-3 text-center mb-0">Welcome to HubNero,</p>
          <p class="fz-30 font-weight-medium lh-3 text-center mb-7">{{ me.firstName }}!</p>
          <v-img src="@/assets/svg/Greetings.svg" width="300" height="300" class="mb-7"></v-img>
          <span class="font-weight-medium" style="align-self: start"> Last updates: </span>
          <ul class="mb-7">
            <li>
              <a @click="route('/organic-search/top-pages')">Top Pages</a>
              : fixed bug when custom period selected
            </li>
            <li>
              <a @click="route('/maintenance/tools')">Tools</a>
              : usability/validation update, active option
            </li>
            <li>
              <a @click="route('/maintenance/hostings-registrators')">Hostings & Registrators</a>
              : clean up fields, functional update
            </li>
            <li>Changed order staging logic (tool-based)</li>
          </ul>
          <v-btn
            elevation="0"
            block
            color="#843DCB"
            height="48"
            class="fz-14 rounded-3 text-none font-weight-regular welcome-btn"
            @click="$emit('checkBirthday')">
            Let’s Go!
          </v-btn>
        </v-sheet>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cookieReminder"
      content-class="width-unset  elevation-0"
      overlay-color="rgba(57,57,74,0.75)"
      overlay-opacity="1"
      max-width="600">
      <v-card
        class="relative d-flex align-center flex-column py-12 justify-center"
        style="padding-left: 73px; padding-right: 73px">
        <v-icon
          style="position: absolute; right: 16px; top: 16px; z-index: 9999; cursor: pointer"
          @click="cookieReminder = false">
          $close
        </v-icon>
        <v-sheet class="d-flex justify-center flex-column align-center">
          <p class="fz-30 font-weight-medium lh-3 text-center mb-0">
            Don't forget to clear your cookie once a week!
          </p>
        </v-sheet>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import gql from 'graphql-tag';
import AvatarsItem from '@/components/md-ui/table/item/AvatarsItem';
import {getFeatureFlag} from '@/helpers/getFeatureFlag';
export default {
  name: 'SideBar',
  props: ['sidebarMini', 'myBirthday', 'birthday', 'welcomeShow'],
  emits: ['showSidebar', 'checkBirthday', 'addSnack', 'update:welcomeShow'],
  components: {
    AvatarsItem,
  },
  data() {
    return {
      featureIsActive: false,
      isUserDeveloper: false,
      showWIPTabs: process.env.TARGET !== 'production',
      menu: [
        {
          name: 'Insights',
          url: '/',
          icon: '$menuInsights',
          permission: 'useInsights',
        },
      ],
      accordeon: {
        accordeonMTShow: null,
        accordeonFNShow: null,
        accordeonCMShow: null,
        accordeonMPShow: null,
        accordeonLBShow: null,
        accordeonOShow: null,
        accordeonMMShow: null,
      },
      isFirstMount: true,
      brefShow: null,
      briefShow: null,
      accordeonFN: [
        {
          name: 'Finance',
          icon: '$menuFinance',
          subpages: [
            {
              name: 'Tasks',
              url: '/finance/tasks',
              permission: 'readFinanceTasks',
            },
            {
              name: 'Balance',
              url: '/finance/balance',
              permission: 'readFinanceBalance',
            },
            {
              name: 'My Cards',
              url: '/finance/my-cards',
              permission: 'readFinanceCards',
            },
          ],
        },
      ],
      accordeonMM: [
        {
          name: 'Management',
          icon: '$management',
          subpages: [
            {
              name: 'PnL',
              url: '/management/pl',
              permission: 'readManagementPl',
            },
            {
              name: 'Unit Economic',
              url: '/management/unit-economic',
              permission: 'readManagementUnitEconomics',
            },
            {
              name: 'Revenue Cabs',
              url: '/management/RevenueCabs',
              permission: 'readFinanceRevenueCabs',
            },
          ],
        },
      ],
      accordeonO: [
        {
          name: 'Orders',
          icon: '$menuOrders',
          subpages: [
            {
              name: 'Order Items',
              url: '/orders/orderitems',
              permission: 'readFinanceOrders',
            },
            {
              name: 'Suppliers',
              url: '/orders/suppliers',
              permission: 'readSuppliers',
            },
          ],
        },
      ],
      accordeonMT: [
        {
          name: 'Maintenance',
          icon: '$menuMaintenance',
          subpages: [
            {
              name: 'Tools',
              url: '/maintenance/tools',
              permission: 'readMaintenanceTools',
            },
            {
              name: 'Projects',
              url: '/maintenance/projects',
              permission: 'readMaintenanceProjects',
            },
            {
              name: 'Google Connections',
              url: '/maintenance/gsc-connections',
              permission: 'readMaintenanceGscConnections',
            },
            {
              name: 'Emails',
              url: '/maintenance/emails',
              permission: 'readMaintenanceEmails',
            },
            {
              name: 'Hostings & Registrators',
              url: '/maintenance/hostings-registrators',
              permission: 'readMaintenanceHostingsRegistrators',
            },
            {
              name: 'Project Management',
              url: '/maintenance/project-management',
              permission: 'readMaintenanceProjectManagement',
            },
          ],
        },
      ],
      accordeonCM: [
        {
          name: 'Content Marketing',
          icon: '$menuContentMarketing',
          subpages: [
            {
              name: 'Content Items',
              url: '/contentmarketing/contentitems',
              permission: 'readContentMarketingContent',
            },
            {
              name: 'Copywriters',
              url: '/contentmarketing/copywriters',
              permission: 'readContentMarketingCopywritersTab',
            },
          ],
        },
      ],
      accordeonMP: [
        {
          name: 'Marketing Plan',
          icon: '$menuMarketingPlan',
          subpages: [
            {
              name: 'Content Plan',
              url: '/marketing-plan/content-plan',
              permission: 'readPlanningContent',
            },
            {
              name: 'Link Building Plan',
              url: '/marketing-plan/link-building-plan',
              permission: 'readPlanningLinkbuilding',
            },
            {
              name: 'PBN Plan',
              url: '/marketing-plan/pbn-plan',
              permission: 'readPbnPlanning',
            },
          ],
        },
      ],
      accordeonLB: [
        {
          name: 'Link Building',
          icon: '$menuLinkBuilding',
          subpages: [
            {
              name: 'Link Building Item',
              url: '/link-building/link-building-item',
              permission: 'readLinkbuildingItem',
            },
            {
              name: 'Accounts',
              url: '/link-building/accounts',
              permission: 'readLinkbuildingAccount',
            },
            {
              name: 'Outreach Base',
              url: '/link-building/outreach-base',
              permission: 'readLinkbuildingOutreachBase',
            },
          ],
        },
      ],
      myBirthdayShow: false,
      birthdayShow: false,
      cookieReminder: false,
      prevRoute: null,
      brefList: [
        {
          name: 'Traffic',
          url: '/brief/traffic',
        },
        {
          name: 'Keyword description',
          url: '/brief/keyword-description',
        },
        {
          name: 'Ref Domains',
          url: '/brief/ref-domains',
        },
        {
          name: 'Content',
          url: '/brief/content',
        },
        {
          name: 'Costs',
          url: '/brief/costs',
        },
      ],
      watchTime: {
        day: new Date().getDay(),
        hour: new Date().getHours(),
        minute: new Date().getMinutes(),
      },
    };
  },
  computed: {
    permissions() {
      return this.$store.getters.getMe ? this.$store.getters.getMe.tabPermissions : {};
    },
    christmasMode() {
      return this.$store.getters.getChristmasMode;
    },
    selectedTool() {
      return this.$store.getters.getSelectedTool;
    },
    me() {
      return this.$store.getters.getMe;
    },
    welcomeShowOpen: {
      get() {
        return this.welcomeShow;
      },
      set(val) {
        this.$emit('update:welcomeShow', val);
      },
    },
  },
  updated() {
    this.getMe();
  },
  mounted() {
    this.checkFeatureFlag();
    const accord = JSON.parse(localStorage.getItem('sideAccord'));
    this.accordeon = {
      ...this.accordeon,
      ...accord,
    };
    const path = this.$route.path;
    if (this.isFirstMount) {
      setTimeout(() => {
        if (this.$route.path === path) {
          this.activity();
        }
      }, this.$store.state.triggerMonitoringTimeout);
      this.isFirstMount = false;
    }

    this.checkSchedule();
    setInterval(this.checkSchedule, 60000);
  },
  methods: {
    // FF - Feedback
    async checkFeatureFlag() {
      this.featureIsActive = await getFeatureFlag('Feedback', this.$apollo);
    },
    brefUpdate() {
      this.$store.commit('updateDomainPortal', '');
    },
    showBirth() {
      const self = this;
      setTimeout(function () {
        if (self.myBirthday.length) self.myBirthdayShow = true;
        else if (self.birthday.length) self.birthdayShow = true;
      }, 0);
    },
    birthdayCheck() {
      this.myBirthdayShow = false;
      if (this.birthday.length) this.birthdayShow = true;
    },
    agesToday(birthday) {
      const year = new Date(birthday).getFullYear();
      const currentYear = new Date().getFullYear();
      return currentYear - year;
    },
    filterByPermissions(accordeon) {
      return accordeon.map((e) => {
        return {
          ...e,
          subpages: e.subpages.filter((e) => this.permissions[e.permission]),
        };
      });
    },
    permissionList(...state) {
      const booleans = state.map((permission) => this.permission(permission));
      return booleans.find((e) => e);
    },
    permission(state) {
      return this.permissions[state];
    },
    route(r) {
      this.$router.push(r);
      this.$emit('checkBirthday');
    },
    closeWindow() {
      this.birthdayShow = false;
    },
    cookieReminderOpen() {
      this.cookieReminder = true;
    },
    checkSchedule() {
      const today = new Date();
      const currentDay = today.getDay();
      const currentHour = today.getHours();
      const currentMinute = today.getMinutes();
      if (currentDay === 1 && currentHour === 12 && currentMinute === 0) {
        this.cookieReminderOpen();
      }
    },
    async getMe() {
      await this.$apollo
        .query({
          query: gql`
            query getMyID {
              auth {
                me {
                  id
                  team {
                    name
                    defaultColor
                  }
                }
              }
            }
          `,
        })
        .then((data) => {
          const {team} = data.data.auth.me;
          if (team && team.name === 'Frosty') {
            this.isUserDeveloper = true;
          }
        })
        .catch(() => {
          this.$router.push('/login');
        });
    },
    async activity() {
      await this.$apollo
        .mutate({
          mutation: gql`
            mutation Activity($path: String!) {
              technical {
                activity {
                  writeActivityPath(path: $path)
                }
              }
            }
          `,
          variables: {
            path: this.$route.path,
          },
          fetchPolicy: 'no-cache',
        })
        .catch((err) => {
          this.$emit('addSnack', 'Success', 'Success', err.message);
        });
    },
  },
  watch: {
    selectedTool: {
      handler() {
        if (this.selectedTool.selectedDomain) this.brefShow = 0;
      },
      deep: true,
    },
    accordeon: {
      handler() {
        localStorage.setItem('sideAccord', JSON.stringify(this.accordeon));
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.sidebar {
  background-color: #F4F4F6 !important;
  .v-list-item--active {
    width: 100%;
    transition: 0.26s;
    background-color: #EAEAEB;
    border-radius: 6px !important;
  }

  .v-theme--light.v-list-item:not(.v-list-item--disabled) {
    &:hover .v-list-item__content span {
      color: #843DCB !important;
      .v-icon {
        path: #843DCB;
      }
    }
  }
}
.nav-accordeon {
  margin-top: 8px !important;
  .v-expansion-panel {
    background-color: transparent !important;

    &:before,
    &:after {
      content: none !important;
    }
  }

  .v-expansion-panel-title:hover .v-list-item__content span {
    color: #843DCB;
  }

  .v-expansion-panel-title__icon {
    position: absolute;
    left: 192px;
  }

  .v-expansion-panel-text__wrapper {
    padding: 0 !important;

    .v-list-item:first-child {
      margin-top: 8px;
    }

    .expand-list {
      .v-list-item--active {
        color: #843DCB !important;

        &:before {
          opacity: 0 !important;
        }
      }
    }
  }
}
.v-list-item__overlay,
.v-expansion-panel-title__overlay {
  display: none !important;
}
.welcome-btn {
  .v-btn__content {
    color: #fff;
  }
  &:hover {
    .v-btn__content {
      color: #333 !important;
    }
  }
}
</style>
