import {createApp} from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from '@/plugins/vuetify';
import apolloProvider from '@/vue-apollo';
import '@babel/polyfill';
import '@/assets/scss/variables.scss';
import VueTheMask from 'vue-the-mask';
import frontErrorHandler from '@/helpers/frontErrorHandler';

const app = createApp(App);

app.config.errorHandler = (error, instance, info) => {
  const componentName = instance?.type?.name || instance?.type?.__file || 'Anonymous Component';
  return frontErrorHandler(error, instance, info, componentName);
};
window.onerror = (message, url, line, col, errorObj) => {
  return frontErrorHandler(message, app);
};

app.use(store).use(router).use(vuetify).use(VueTheMask).use(apolloProvider).mount('#app');
