<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <g id="Group_2">
        <path
          id="Path"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15 17.5H5C3.61917 17.5 2.5 16.3808 2.5 15V5C2.5 3.61917 3.61917 2.5 5 2.5H15C16.3808 2.5 17.5 3.61917 17.5 5V15C17.5 16.3808 16.3808 17.5 15 17.5Z"
          stroke="#C2C9DA"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          id="Path_2"
          d="M8.32302 6.26051C8.89254 6.83003 8.89254 7.75339 8.32302 8.3229C7.75351 8.89242 6.83014 8.89242 6.26063 8.3229C5.69112 7.75339 5.69112 6.83002 6.26063 6.26051C6.83015 5.691 7.75352 5.691 8.32302 6.26051"
          stroke="#C2C9DA"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          id="Path_3"
          d="M17.5002 8.42421C17.1252 8.37087 16.7443 8.33337 16.3543 8.33337C11.9243 8.33337 8.3335 11.9242 8.3335 16.3542C8.3335 16.7442 8.371 17.125 8.42433 17.5"
          stroke="#C2C9DA"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'licenseLogo',
};
</script>

<style scoped></style>
