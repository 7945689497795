<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6.66667H9.33335C8.62611 6.66667 7.94783 6.94762 7.44774 7.44772C6.94764 7.94781 6.66669 8.62609 6.66669 9.33333V25.3333C6.66669 26.0406 6.94764 26.7189 7.44774 27.219C7.94783 27.719 8.62611 28 9.33335 28H22.6667C23.3739 28 24.0522 27.719 24.5523 27.219C25.0524 26.7189 25.3334 26.0406 25.3334 25.3333V9.33333C25.3334 8.62609 25.0524 7.94781 24.5523 7.44772C24.0522 6.94762 23.3739 6.66667 22.6667 6.66667H20M12 6.66667C12 5.95942 12.281 5.28115 12.7811 4.78105C13.2812 4.28095 13.9594 4 14.6667 4H17.3334C18.0406 4 18.7189 4.28095 19.219 4.78105C19.7191 5.28115 20 5.95942 20 6.66667M12 6.66667C12 7.37391 12.281 8.05219 12.7811 8.55229C13.2812 9.05238 13.9594 9.33333 14.6667 9.33333H17.3334C18.0406 9.33333 18.7189 9.05238 19.219 8.55229C19.7191 8.05219 20 7.37391 20 6.66667M12 16H20M12 21.3333H20" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ContentAudit',
};
</script>

<style scoped></style>
