<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.68054 16.6667C4.68054 14.5833 6.34721 13 8.34721 13H12.5972C14.6805 13 16.2639 14.6667 16.2639 16.6667"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M13.0139 4.33325C14.4306 5.74992 14.4306 7.99992 13.0139 9.33325C11.5972 10.6666 9.34724 10.7499 8.01391 9.33325C6.68058 7.91659 6.59724 5.66659 8.01391 4.33325C9.43058 2.99992 11.5972 2.99992 13.0139 4.33325"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'user',
};
</script>

<style scoped></style>
