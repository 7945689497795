<template>
  <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.59993 3.2C5.79993 2.93333 6.19993 2.93333 6.39993 3.2L9.89993 7.86667C10.1471 8.19628 9.91195 8.66667 9.49993 8.66667H2.49993C2.08791 8.66667 1.85272 8.19628 2.09993 7.86667L5.59993 3.2Z"
      fill="#00916E"/>
  </svg>
</template>

<script>
export default {
  name: 'moveUp',
};
</script>

<style scoped></style>
