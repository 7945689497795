<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0021 9.33265V7.5219C16.0021 7.08005 15.8262 6.6557 15.5136 6.34307L13.1559 3.98542C12.8433 3.67279 12.4189 3.49689 11.9771 3.49689H5.99793C5.07671 3.49689 4.33057 4.24303 4.33057 5.16425V16.8358C4.33057 17.757 5.07671 18.5031 5.99793 18.5031H9.33265"
      stroke="#39D094"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M16.0021 7.66529H12.6674C12.2072 7.66529 11.8337 7.2918 11.8337 6.83161V3.49689"
      stroke="#39D094"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M14.7516 19.3368C12.6799 19.3368 11 17.6569 11 15.5852C11 13.5135 12.6799 11.8337 14.7516 11.8337C16.8241 11.8337 18.5031 13.5135 18.5031 15.5852C18.5031 17.6569 16.8241 19.3368 14.7516 19.3368"
      stroke="#39D094"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M14.7516 14.0888V17.0809" stroke="#39D094" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16.2481 15.5852H13.256" stroke="#39D094" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'newContent',
};
</script>

<style scoped></style>
