<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.44818 5.20198L5.40443 8.38873M9.60968 12.5955L12.7979 13.5517M8.76233 9.23774L13.0816 4.91849M3 4.125C3 4.27274 3.0291 4.41903 3.08564 4.55552C3.14217 4.69201 3.22504 4.81603 3.3295 4.9205C3.43397 5.02496 3.55799 5.10783 3.69448 5.16436C3.83097 5.2209 3.97726 5.25 4.125 5.25C4.27274 5.25 4.41903 5.2209 4.55552 5.16436C4.69201 5.10783 4.81603 5.02496 4.9205 4.9205C5.02496 4.81603 5.10783 4.69201 5.16436 4.55552C5.2209 4.41903 5.25 4.27274 5.25 4.125C5.25 3.97726 5.2209 3.83097 5.16436 3.69448C5.10783 3.55799 5.02496 3.43397 4.9205 3.3295C4.81603 3.22504 4.69201 3.14217 4.55552 3.08564C4.41903 3.0291 4.27274 3 4.125 3C3.97726 3 3.83097 3.0291 3.69448 3.08564C3.55799 3.14217 3.43397 3.22504 3.3295 3.3295C3.22504 3.43397 3.14217 3.55799 3.08564 3.69448C3.0291 3.83097 3 3.97726 3 4.125ZM12.75 4.125C12.75 4.42337 12.8685 4.70952 13.0795 4.9205C13.2905 5.13147 13.5766 5.25 13.875 5.25C14.1734 5.25 14.4595 5.13147 14.6705 4.9205C14.8815 4.70952 15 4.42337 15 4.125C15 3.82663 14.8815 3.54048 14.6705 3.3295C14.4595 3.11853 14.1734 3 13.875 3C13.5766 3 13.2905 3.11853 13.0795 3.3295C12.8685 3.54048 12.75 3.82663 12.75 4.125ZM12.75 13.875C12.75 14.1734 12.8685 14.4595 13.0795 14.6705C13.2905 14.8815 13.5766 15 13.875 15C14.1734 15 14.4595 14.8815 14.6705 14.6705C14.8815 14.4595 15 14.1734 15 13.875C15 13.5766 14.8815 13.2905 14.6705 13.0795C14.4595 12.8685 14.1734 12.75 13.875 12.75C13.5766 12.75 13.2905 12.8685 13.0795 13.0795C12.8685 13.2905 12.75 13.5766 12.75 13.875ZM3 11.625C3 12.5201 3.35558 13.3785 3.98851 14.0115C4.62145 14.6444 5.47989 15 6.375 15C7.27011 15 8.12855 14.6444 8.76149 14.0115C9.39442 13.3785 9.75 12.5201 9.75 11.625C9.75 10.7299 9.39442 9.87145 8.76149 9.23851C8.12855 8.60558 7.27011 8.25 6.375 8.25C5.47989 8.25 4.62145 8.60558 3.98851 9.23851C3.35558 9.87145 3 10.7299 3 11.625Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'menuSemantics',
};
</script>

<style scoped></style>
