<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9023 21.3355L11.2353 26.6711M27.242 10.6501V7.23368C27.242 5.445 25.792 3.995 24.0033 3.995H7.23368C5.445 3.995 3.995 5.445 3.995 7.23368V18.0969C3.995 19.8855 5.445 21.3356 7.23368 21.3356H15.3612M9.23451 26.6711H13.3543M28.5385 27.9997H18.0473C17.6016 27.9997 17.2403 27.6384 17.2403 27.1927V26.5374C17.2456 24.673 18.7556 23.1629 20.62 23.1576H25.9657C27.8301 23.1629 29.3402 24.673 29.3455 26.5374V27.1927C29.3455 27.6384 28.9842 27.9997 28.5385 27.9997ZM23.2926 19.7895C21.5111 19.7859 20.0692 18.3397 20.071 16.5582C20.0728 14.7766 21.5175 13.3333 23.2991 13.3333C25.0806 13.3333 26.5254 14.7766 26.5271 16.5582C26.5289 18.3397 25.0871 19.7859 23.3055 19.7895H23.2926Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'UsersActivity',
};
</script>

<style scoped></style>
