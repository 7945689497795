<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.55575 15.7783C6.11992 15.7783 3.33325 12.9916 3.33325 9.55575C3.33325 6.11992 6.11992 3.33325 9.55575 3.33325C12.9933 3.33325 15.7783 6.11992 15.7783 9.55575C15.7783 12.9916 12.9933 15.7783 9.55575 15.7783"
      stroke="#24C182"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M16.6666 16.6666L13.9583 13.9583"
      stroke="#24C182"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M8.51929 8.51919L9.55595 7.48169V11.6309"
      stroke="#24C182"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M8.52246 11.6342H10.5883" stroke="#24C182" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'profilerMainKeyword',
};
</script>

<style scoped></style>
