<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5032 9.99971V13.3344C17.5032 15.6366 15.6369 17.5028 13.3348 17.5028H6.66532C4.36318 17.5028 2.49692 15.6366 2.49692 13.3344V6.66498C2.49692 4.36284 4.36318 2.49658 6.66532 2.49658H10"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M10.2575 9.11533L10.3776 8.63429C10.5234 8.05009 10.8251 7.51644 11.2505 7.09032L15.3263 3.01362C15.7679 2.54902 16.4268 2.36052 17.0473 2.52125C17.6678 2.68197 18.1523 3.16663 18.3128 3.78719C18.4733 4.40775 18.2846 5.06655 17.8199 5.50799L13.7432 9.58302C13.3176 10.0099 12.7834 10.3123 12.1983 10.4576L11.5939 10.6093"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.48396 13.3344C10.6654 13.3305 11.6297 12.3879 11.6604 11.2069C11.6911 10.0258 10.777 9.03437 9.59734 8.96922C7.72239 8.87418 7.28888 11.1368 6.24844 11.7312C7.00826 12.7526 8.21105 13.3485 9.48396 13.3344V13.3344Z"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'CONTENT_PROOF_READING',
};
</script>

<style scoped></style>
