<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="#E5E8EF" />
  </svg>
</template>

<script>
export default {
  name: 'RadioOff',
};
</script>

<style scoped></style>
