<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.929 10.3616V16.3028L11.2591 19.1493M12.9318 26.6711C7.57133 25.4109 3.85911 20.5241 4.0827 15.0221C4.30629 9.52001 8.40277 4.95054 13.8478 4.12947C18.0288 3.499 22.1018 5.21478 24.5987 8.39571C24.731 8.5642 24.8588 8.7368 24.982 8.91339M28.526 27.9997H18.0348C17.5891 27.9997 17.2278 27.6384 17.2278 27.1927V26.5374C17.2331 24.673 18.7432 23.1629 20.6076 23.1576H25.9532C27.8176 23.1629 29.3277 24.673 29.333 26.5374V27.1927C29.333 27.6384 28.9717 27.9997 28.526 27.9997ZM23.2801 19.7895C21.4986 19.7859 20.0567 18.3397 20.0585 16.5582C20.0603 14.7766 21.505 13.3333 23.2866 13.3333C25.0681 13.3333 26.5129 14.7766 26.5146 16.5582C26.5164 18.3397 25.0746 19.7859 23.293 19.7895H23.2801Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'UsersPerformance',
};
</script>

<style scoped></style>
