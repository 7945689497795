<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" fill="#F3F9FF" />
    <path
      d="M17.591 14.409C18.4697 15.2877 18.4697 16.7123 17.591 17.591C16.7123 18.4697 15.2877 18.4697 14.409 17.591C13.5303 16.7123 13.5303 15.2877 14.409 14.409C15.2877 13.5303 16.7123 13.5303 17.591 14.409"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.375 16C10.375 16.2475 10.3975 16.495 10.4275 16.735L9.10416 17.77C8.81083 18 8.73083 18.4108 8.91749 18.7333L10.0942 20.7692C10.28 21.0917 10.675 21.2275 11.0208 21.0892L12.2058 20.6133C12.44 20.5192 12.7008 20.5567 12.9108 20.6958C13.0942 20.8175 13.2842 20.9292 13.4808 21.0292C13.7058 21.1433 13.8692 21.3475 13.905 21.5975L14.0858 22.8583C14.1383 23.2267 14.4542 23.5 14.8258 23.5H17.1733C17.545 23.5 17.8608 23.2267 17.9133 22.8583L18.0942 21.5983C18.13 21.3483 18.295 21.1425 18.5208 21.0292C18.7167 20.9308 18.9058 20.82 19.0883 20.6992C19.3 20.5592 19.5617 20.5192 19.7967 20.6142L20.9792 21.0892C21.3242 21.2275 21.7192 21.0917 21.9058 20.7692L23.0825 18.7333C23.2692 18.4108 23.1892 17.9992 22.8958 17.77L21.5725 16.735C21.6025 16.495 21.625 16.2475 21.625 16C21.625 15.7525 21.6025 15.505 21.5725 15.265L22.8958 14.23C23.1892 14 23.2692 13.5892 23.0825 13.2667L21.9058 11.2308C21.72 10.9083 21.325 10.7725 20.9792 10.9108L19.7967 11.3858C19.5617 11.48 19.3 11.4408 19.0883 11.3008C18.9058 11.18 18.7167 11.0692 18.5208 10.9708C18.295 10.8575 18.13 10.6517 18.0942 10.4017L17.9142 9.14167C17.8617 8.77333 17.5458 8.5 17.1742 8.5H14.8267C14.455 8.5 14.1392 8.77333 14.0867 9.14167L13.905 10.4033C13.8692 10.6525 13.705 10.8575 13.4808 10.9717C13.2842 11.0717 13.0942 11.1842 12.9108 11.305C12.7 11.4433 12.4392 11.4808 12.205 11.3867L11.0208 10.9108C10.675 10.7725 10.28 10.9083 10.0942 11.2308L8.91749 13.2667C8.73083 13.5892 8.81083 14.0008 9.10416 14.23L10.4275 15.265C10.3975 15.505 10.375 15.7525 10.375 16V16Z"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'LBPgear',
};
</script>

<style scoped></style>
