<template>
  <svg width="20" height="32" viewBox="0 0 20 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 27.7178C7.75 29.8701 7.09771 25.9326 8.3125 25.9326C8.875 25.9326 9.2005 27.0576 10 27.0576C10.7995 27.0576 11.125 25.9326 11.6875 25.9326C12.9023 25.9326 12.25 29.8701 19 27.7178V27.6998C19 29.4761 14.9995 33.0263 10 29.4761C5.0005 33.0263 1 29.4761 1 27.6998"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M8.65001 20.9826C9.39588 20.5327 10.6041 20.5327 11.35 20.9826M7.85919 19.4109C8.91361 20.4654 8.91361 22.1749 7.85919 23.2293C6.80477 24.2837 5.09523 24.2837 4.04081 23.2293C2.9864 22.1749 2.9864 20.4653 4.04081 19.4109C5.09523 18.3565 6.80478 18.3565 7.85919 19.4109ZM15.9592 19.4109C17.0136 20.4654 17.0136 22.1749 15.9592 23.2293C14.9048 24.2837 13.1952 24.2837 12.1408 23.2293C11.0864 22.1749 11.0864 20.4653 12.1408 19.4109C13.1952 18.3565 14.9048 18.3565 15.9592 19.4109Z"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M1.13672 13.4556C1.13672 13.4556 5.20641 16.205 10.0705 16.205C14.9345 16.205 18.9997 13.4556 18.9997 13.4556"
      stroke-linecap="round" />
    <path
      d="M2.8595 2.20706L4.86308 11.9126C4.92489 12.2121 5.11946 12.4663 5.4026 12.5817C6.21233 12.9115 8.04535 13.5358 10.1437 13.4462C12.1848 13.3589 13.939 12.8475 14.7603 12.5684C15.0739 12.4618 15.2979 12.1926 15.3658 11.8684L17.3889 2.20989C17.5191 1.58862 17.0449 1.00488 16.4102 1.00488H3.83885C3.20528 1.00488 2.73141 1.58657 2.8595 2.20706Z" />
  </svg>
</template>

<script>
export default {
  name: 'Capitalist',
};
</script>

<style scoped></style>
