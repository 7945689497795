<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" fill="#F3F9FF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6667 9.33333L13.75 7.97916C13.9083 7.78166 14.1475 7.66666 14.4008 7.66666H17.6C17.8533 7.66666 18.0925 7.78166 18.2508 7.97916L19.3333 9.33333V9.33333V10.1667C19.3333 10.6267 18.96 11 18.5 11H13.5C13.04 11 12.6667 10.6267 12.6667 10.1667V9.33333V9.33333H12.6667Z"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M12.6667 9.33333H11C10.0792 9.33333 9.33334 10.0792 9.33334 11V21.8333C9.33334 22.7542 10.0792 23.5 11 23.5H21C21.9208 23.5 22.6667 22.7542 22.6667 21.8333V11C22.6667 10.0792 21.9208 9.33333 21 9.33333H19.3333"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M12.6667 15.1667H19.3333" stroke="#1E96FC" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.6667 19.3333H19.3333" stroke="#1E96FC" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'OrderItemIcon',
};
</script>

<style scoped></style>
