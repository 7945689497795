<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.6667 22.6667L19.3333 28M13.3333 22.6667L12.6667 28M10.8533 28H21.1467M16 9.33333V8M16 9.33333H15.2053C14.172 9.33333 13.3333 10.172 13.3333 11.2053C13.3333 12.064 13.9173 12.8133 14.7493 13.024L17.2493 13.6507C18.0827 13.86 18.6667 14.608 18.6667 15.4667C18.6667 16.5 17.8293 17.3387 16.7947 17.3387H15.3333C14.62 17.3387 14.0307 16.9467 13.6853 16.3813M16 9.33333H16.6667C17.3773 9.33333 17.9653 9.72267 18.312 10.2853M16 17.3333V18.6667M24.572 22.6667H7.428C5.53467 22.6667 4 21.132 4 19.2387V7.428C4 5.53467 5.53467 4 7.428 4H24.5707C26.4653 4 28 5.53467 28 7.428V19.2373C28 21.132 26.4653 22.6667 24.572 22.6667Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Conversions',
};
</script>

<style scoped></style>
