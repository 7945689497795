<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6024 18.3332 10C18.3332 5.39765 14.6022 1.66669 9.99984 1.66669C5.39746 1.66669 1.6665 5.39765 1.6665 10C1.6665 14.6024 5.39746 18.3334 9.99984 18.3334Z"
      fill="url(#paint0_linear_6027_359023)" />
    <path
      d="M7.30762 10.6092L8.2963 13.3457C8.2963 13.3457 8.41991 13.6018 8.55227 13.6018C8.68463 13.6018 10.6533 11.5537 10.6533 11.5537L12.8425 7.32526L7.3429 9.90283L7.30762 10.6092Z"
      fill="#C8DAEA" />
    <path
      d="M8.61841 11.311L8.42862 13.3281C8.42862 13.3281 8.34917 13.9462 8.96709 13.3281C9.58501 12.7101 10.1765 12.2335 10.1765 12.2335"
      fill="#A9C6D8" />
    <path
      d="M7.3254 10.7068L5.29165 10.0442C5.29165 10.0442 5.04859 9.94556 5.12686 9.72195C5.14297 9.67583 5.17547 9.6366 5.27269 9.56917C5.72331 9.25507 13.6134 6.41917 13.6134 6.41917C13.6134 6.41917 13.8362 6.3441 13.9676 6.39403C14.0001 6.40409 14.0293 6.42261 14.0523 6.44768C14.0753 6.47275 14.0912 6.50349 14.0985 6.53674C14.1126 6.59546 14.1186 6.65588 14.1161 6.71625C14.1155 6.76847 14.1091 6.81688 14.1044 6.89278C14.0563 7.66813 12.6182 13.4548 12.6182 13.4548C12.6182 13.4548 12.5322 13.7934 12.2239 13.805C12.1482 13.8074 12.0727 13.7946 12.002 13.7673C11.9313 13.7399 11.8669 13.6986 11.8125 13.6458C11.2076 13.1255 9.11672 11.7203 8.6547 11.4113C8.64428 11.4042 8.6355 11.395 8.62896 11.3842C8.62242 11.3734 8.61827 11.3613 8.61679 11.3488C8.61033 11.3163 8.64574 11.2759 8.64574 11.2759C8.64574 11.2759 12.2864 8.03979 12.3833 7.70007C12.3908 7.67375 12.3625 7.66077 12.3244 7.67229C12.0826 7.76125 7.89081 10.4084 7.42817 10.7006C7.39487 10.7106 7.35967 10.7128 7.3254 10.7068Z"
      fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_6027_359023"
        x1="9.99984"
        y1="18.3334"
        x2="9.99984"
        y2="1.66669"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#1D93D2" />
        <stop offset="1" stop-color="#38B0E3" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'telegram',
};
</script>

<style scoped></style>
