<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.90083 17.4535C9.14677 17.421 9.3952 17.4877 9.59112 17.6386L10.494 18.3314C10.7925 18.5606 11.2076 18.5606 11.5052 18.3314L12.4423 17.6119C12.6174 17.4777 12.8383 17.4185 13.0567 17.4477L14.2289 17.6019C14.6015 17.6511 14.9609 17.4435 15.1051 17.0958L15.5561 16.0054C15.6403 15.8011 15.802 15.6394 16.0063 15.5552L17.0967 15.1042C17.4444 14.9608 17.652 14.6006 17.6028 14.228L17.4544 13.0984C17.4219 12.8524 17.4886 12.604 17.6395 12.4081L18.3323 11.5052C18.5615 11.2067 18.5615 10.7916 18.3323 10.4939L17.6128 9.55688C17.4786 9.3818 17.4194 9.16088 17.4486 8.94245L17.6028 7.7703C17.652 7.39764 17.4444 7.03833 17.0967 6.8941L16.0063 6.44308C15.802 6.35888 15.6403 6.19714 15.5561 5.99289L15.1051 4.90244C14.9617 4.55479 14.6015 4.34721 14.2289 4.39639L13.0567 4.55062C12.8383 4.58064 12.6174 4.52144 12.4431 4.38806L11.5061 3.66859C11.2076 3.43933 10.7925 3.43933 10.4948 3.66859L9.55777 4.38806C9.3827 4.52144 9.16177 4.58064 8.94335 4.55229L7.77119 4.39806C7.39854 4.34887 7.03922 4.55646 6.89499 4.9041L6.44481 5.99456C6.35977 6.19798 6.19804 6.35971 5.99462 6.44475L4.90417 6.89493C4.55652 7.03916 4.34893 7.39848 4.39812 7.77113L4.55235 8.94329C4.5807 9.16171 4.52151 9.38264 4.38812 9.55688L3.66865 10.4939C3.43939 10.7924 3.43939 11.2076 3.66865 11.5052L4.38812 12.4422C4.52234 12.6173 4.58153 12.8382 4.55235 13.0567L4.39812 14.2288C4.34893 14.6015 4.55652 14.9608 4.90417 15.105L5.99462 15.556C6.19887 15.6402 6.36061 15.802 6.44481 16.0062L6.89583 17.0967C7.03922 17.4443 7.39937 17.6519 7.77203 17.6027L8.90083 17.4535"
      stroke="#F152C5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M13.3334 9.83365L10.4147 12.7524L8.66479 11.0016"
      stroke="#F152C5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'profit',
};
</script>

<style scoped></style>
