<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" fill="#F9F4FE" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9167 8.08301H22.25C23.1708 8.08301 23.9167 8.82884 23.9167 9.74967V13.083C23.9167 14.0038 23.1708 14.7497 22.25 14.7497H18.9167C17.9958 14.7497 17.25 14.0038 17.25 13.083V9.74967C17.25 8.82884 17.9958 8.08301 18.9167 8.08301Z"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.75001 17.25H13.0833C14.0042 17.25 14.75 17.9958 14.75 18.9167V22.25C14.75 23.1708 14.0042 23.9167 13.0833 23.9167H9.75001C8.82918 23.9167 8.08334 23.1708 8.08334 22.25V18.9167C8.08334 17.9958 8.82918 17.25 9.75001 17.25Z"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.08334 13.083V9.74967C8.08334 8.82884 8.82918 8.08301 9.75001 8.08301H13.0833C14.0042 8.08301 14.75 8.82884 14.75 9.74967V13.083C14.75 14.0038 14.0042 14.7497 13.0833 14.7497H9.75001C8.82918 14.7497 8.08334 14.0038 8.08334 13.083Z"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M22.6667 18.5L18.5 22.6658" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22.6667 22.6658L18.5 18.5" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'LBOB1',
};
</script>

<style scoped></style>
