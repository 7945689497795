<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3033 4.6967C18.2323 7.62563 18.2323 12.3744 15.3033 15.3033C12.3744 18.2322 7.62568 18.2322 4.69676 15.3033C1.76783 12.3744 1.76783 7.62562 4.69676 4.6967C7.62569 1.76777 12.3744 1.76777 15.3033 4.6967"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4208 12.7775H9.08165C8.56665 12.7775 8.14832 12.36 8.14832 11.8442V10.0425H11.4208C12.17 10.0425 12.7783 10.65 12.7783 11.4V11.4208C12.7775 12.17 12.17 12.7775 11.4208 12.7775V12.7775Z"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0916 10.0425H8.14832V8.17C8.14832 7.64667 8.57248 7.2225 9.09582 7.2225H11.0916C11.8525 7.2225 12.47 7.83917 12.47 8.60083V8.665C12.47 9.42583 11.8533 10.0425 11.0916 10.0425Z"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M9.07498 7.225H7.22498" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.08331 12.775H7.22498" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.025 6.3V7.225" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.74998 6.3V7.225" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.74998 13.7V12.775" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.025 13.7V12.775" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'btcsmall',
};
</script>

<style scoped></style>
