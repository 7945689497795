<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M11.591 8.40901C12.4697 9.2877 12.4697 10.7123 11.591 11.591C10.7123 12.4697 9.2877 12.4697 8.40901 11.591C7.53033 10.7123 7.53033 9.2877 8.40901 8.40901C9.2877 7.53033 10.7123 7.53033 11.591 8.40901"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.37432 10C4.37432 10.2475 4.39682 10.495 4.42682 10.735L3.10349 11.77C2.81016 12 2.73016 12.4108 2.91682 12.7333L4.09349 14.7692C4.27932 15.0917 4.67432 15.2275 5.02016 15.0892L6.20516 14.6133C6.43932 14.5192 6.70016 14.5567 6.91016 14.6958C7.09349 14.8175 7.28349 14.9292 7.48016 15.0292C7.70516 15.1433 7.86849 15.3475 7.90432 15.5975L8.08516 16.8583C8.13766 17.2267 8.45349 17.5 8.82516 17.5H11.1727C11.5443 17.5 11.8602 17.2267 11.9127 16.8583L12.0935 15.5983C12.1293 15.3483 12.2943 15.1425 12.5202 15.0292C12.716 14.9308 12.9052 14.82 13.0877 14.6992C13.2993 14.5592 13.561 14.5192 13.796 14.6142L14.9785 15.0892C15.3235 15.2275 15.7185 15.0917 15.9052 14.7692L17.0818 12.7333C17.2685 12.4108 17.1885 11.9992 16.8952 11.77L15.5718 10.735C15.6018 10.495 15.6243 10.2475 15.6243 10C15.6243 9.7525 15.6018 9.505 15.5718 9.265L16.8952 8.23C17.1885 8 17.2685 7.58917 17.0818 7.26667L15.9052 5.23083C15.7193 4.90833 15.3243 4.7725 14.9785 4.91083L13.796 5.38583C13.561 5.48 13.2993 5.44083 13.0877 5.30083C12.9052 5.18 12.716 5.06917 12.5202 4.97083C12.2943 4.8575 12.1293 4.65167 12.0935 4.40167L11.9135 3.14167C11.861 2.77333 11.5452 2.5 11.1735 2.5H8.82599C8.45432 2.5 8.13849 2.77333 8.08599 3.14167L7.90432 4.40333C7.86849 4.6525 7.70432 4.8575 7.48016 4.97167C7.28349 5.07167 7.09349 5.18417 6.91016 5.305C6.69932 5.44333 6.43849 5.48083 6.20432 5.38667L5.02016 4.91083C4.67432 4.7725 4.27932 4.90833 4.09349 5.23083L2.91682 7.26667C2.73016 7.58917 2.81016 8.00083 3.10349 8.23L4.42682 9.265C4.39682 9.505 4.37432 9.7525 4.37432 10V10Z"
      stroke="#1E96FC"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'PBNStack',
};
</script>

<style scoped></style>
