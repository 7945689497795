<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8357 4.53939H12.5252C12.2492 4.53954 11.9911 4.40313 11.8357 4.17507L10.9429 2.86119C10.7868 2.63215 10.5272 2.49562 10.2501 2.49687H7.49893C6.57808 2.49687 5.83157 3.24338 5.83157 4.16423V11.6674C5.83157 12.5882 6.57808 13.3347 7.49893 13.3347H15.8357C16.7566 13.3347 17.5031 12.5882 17.5031 11.6674V6.20675C17.5031 5.28589 16.7566 4.53939 15.8357 4.53939Z"
      stroke="#24C182"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M5.83158 5.83157H4.16422C3.24336 5.83157 2.49686 6.57808 2.49686 7.49893V15.8357C2.49686 16.7566 3.24336 17.5031 4.16422 17.5031H13.96C14.8808 17.5031 15.6273 16.7566 15.6273 15.8357V13.3347"
      stroke="#24C182"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'itemFolders',
};
</script>

<style scoped></style>
