<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10 9.9964H17.5031" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.7754 7.26797L17.5038 9.99639" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.7754 12.7248L17.5038 9.9964" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M14.5859 4.06884C11.5043 1.68405 7.10984 2.05637 4.47341 4.92562C1.83699 7.79486 1.83699 12.2051 4.47341 15.0743C7.10984 17.9436 11.5043 18.3159 14.5859 15.9311"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'btnSend',
};
</script>

<style scoped></style>
