<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.6666 29.3333V26.6667M12 20L20 12M14.6666 7.99999L15.284 7.28532C16.5344 6.03509 18.2302 5.33279 19.9984 5.33292C21.7666 5.33304 23.4624 6.03558 24.7126 7.28599C25.9629 8.5364 26.6652 10.2322 26.665 12.0005C26.6649 13.7687 25.9624 15.4644 24.712 16.7147L24 17.3333M17.3333 24L16.804 24.712C15.539 25.9629 13.8317 26.6645 12.0526 26.6645C10.2736 26.6645 8.5663 25.9629 7.30129 24.712C6.67777 24.0955 6.18275 23.3614 5.84491 22.5522C5.50706 21.743 5.3331 20.8749 5.3331 19.998C5.3331 19.1211 5.50706 18.253 5.84491 17.4438C6.18275 16.6346 6.67777 15.9005 7.30129 15.284L7.99996 14.6667M26.6666 22.6667H29.3333M2.66663 9.33332H5.33329M9.33329 2.66666V5.33332" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BacklinkDeleted',
};
</script>

<style scoped></style>
