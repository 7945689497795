<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect class="bg" x="0.5" y="0.5" width="23" height="23" rx="7.5" />
    <path class="ico" d="M15.2317 10.9036L13.0967 8.76855" stroke-linecap="round" stroke-linejoin="round" />
    <path
      class="ico"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.64642 17.2499H6.75V15.3534C6.75 15.1989 6.81125 15.0501 6.92092 14.941L14.6991 7.16227C14.9272 6.93419 15.2964 6.93419 15.5239 7.16227L16.837 8.47536C17.0651 8.70344 17.0651 9.07269 16.837 9.30019L9.05883 17.0789C8.94975 17.1886 8.801 17.2499 8.64642 17.2499V17.2499Z"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <rect class="border" x="0.5" y="0.5" width="23" height="23" rx="7.5" />
  </svg>
</template>

<script>
export default {
  name: 'btnEdit',
};
</script>

<style scoped></style>
