<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.63916 17.0833H3.70332C3.26832 17.0833 2.91666 16.7308 2.91666 16.2967V13.1483C2.91666 12.7133 3.26916 12.3617 3.70332 12.3617H7.63832"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3608 3.70332V17.0833H16.2958C16.7308 17.0833 17.0825 16.7308 17.0825 16.2967V3.70332C17.0825 3.26832 16.73 2.91666 16.2958 2.91666H13.1475C12.7133 2.91666 12.3608 3.26916 12.3608 3.70332H12.3608Z"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M12.3608 17.0833H7.63834V8.42583C7.63834 7.99083 7.99084 7.63916 8.425 7.63916H12.36"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'traffic',
};
</script>

<style scoped></style>
