<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33333 21.3333L13.3333 12L20 18.6667L26.6667 10.6667M18.6667 18.6667C18.6667 19.0203 18.8071 19.3594 19.0572 19.6095C19.3072 19.8595 19.6464 20 20 20C20.3536 20 20.6928 19.8595 20.9428 19.6095C21.1929 19.3594 21.3333 19.0203 21.3333 18.6667C21.3333 18.3131 21.1929 17.9739 20.9428 17.7239C20.6928 17.4738 20.3536 17.3333 20 17.3333C19.6464 17.3333 19.3072 17.4738 19.0572 17.7239C18.8071 17.9739 18.6667 18.3131 18.6667 18.6667ZM12 12C12 12.3536 12.1405 12.6928 12.3905 12.9428C12.6406 13.1929 12.9797 13.3333 13.3333 13.3333C13.687 13.3333 14.0261 13.1929 14.2761 12.9428C14.5262 12.6928 14.6667 12.3536 14.6667 12C14.6667 11.6464 14.5262 11.3072 14.2761 11.0572C14.0261 10.8072 13.687 10.6667 13.3333 10.6667C12.9797 10.6667 12.6406 10.8072 12.3905 11.0572C12.1405 11.3072 12 11.6464 12 12ZM4 21.3333C4 21.687 4.14048 22.0261 4.39052 22.2762C4.64057 22.5262 4.97971 22.6667 5.33333 22.6667C5.68696 22.6667 6.02609 22.5262 6.27614 22.2762C6.52619 22.0261 6.66667 21.687 6.66667 21.3333C6.66667 20.9797 6.52619 20.6406 6.27614 20.3905C6.02609 20.1405 5.68696 20 5.33333 20C4.97971 20 4.64057 20.1405 4.39052 20.3905C4.14048 20.6406 4 20.9797 4 21.3333ZM25.3333 10.6667C25.3333 11.0203 25.4738 11.3594 25.7239 11.6095C25.9739 11.8595 26.313 12 26.6667 12C27.0203 12 27.3594 11.8595 27.6095 11.6095C27.8595 11.3594 28 11.0203 28 10.6667C28 10.3131 27.8595 9.97392 27.6095 9.72387C27.3594 9.47382 27.0203 9.33334 26.6667 9.33334C26.313 9.33334 25.9739 9.47382 25.7239 9.72387C25.4738 9.97392 25.3333 10.3131 25.3333 10.6667Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Economics',
};
</script>

<style scoped></style>
