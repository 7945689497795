<template>
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.25 13.5H5.25V10.5L18.75 4.5L32.25 10.5V13.5Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M7.5 23.25V17.25" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M30 17.25V23.25" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15 17.25V23.25" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22.5 17.25V23.25" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.25 27H32.25V31.5H5.25V27Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'WirePay',
};
</script>

<style scoped></style>
