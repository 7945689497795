<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33337 9.92168V6.74251C3.33337 5.70251 3.97754 4.77084 4.95004 4.40334L11.9109 1.77501C13.0017 1.36418 14.1667 2.16918 14.1667 3.33501V6.12668"
      stroke="#39D094"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M14.3742 11.7508C14.2592 11.7517 14.1667 11.845 14.1667 11.96C14.1667 12.075 14.26 12.1683 14.375 12.1675C14.49 12.1675 14.5834 12.0742 14.5834 11.9592C14.5834 11.8442 14.49 11.7508 14.3742 11.7508"
      stroke="#39D094"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M8.88504 17.5H15C16.375 17.5 17.5 16.375 17.5 15V8.62582C17.5 7.24499 16.3809 6.12582 15 6.12582H5.00004C4.07921 6.12582 3.33337 6.87166 3.33337 7.79249V10.2142"
      stroke="#39D094"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M6.24996 18.3333C3.71913 18.3333 1.66663 16.2808 1.66663 13.75C1.66663 11.2192 3.71913 9.16666 6.24996 9.16666C8.78163 9.16666 10.8333 11.2192 10.8333 13.75C10.8333 16.2808 8.78163 18.3333 6.24996 18.3333"
      stroke="#39D094"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M6.25004 11.9225V15.5783" stroke="#39D094" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.07761 13.75H4.42261" stroke="#39D094" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'income',
};
</script>

<style scoped></style>
