<template>
  <v-menu
    offset="4"
    :close-on-content-click="false"
    content-class="box-shadow-12 mt-1"
    style="z-index: 2100">
    <template #activator="{props}">
      <span v-bind="props" :class="{'cursor-unset': !items.length}">
        <span class="text-grey fz-12" :class="{'text-hover-primary pointer': items.length}">
          {{ lastCollection ? lastCollection : minLastDate }}
        </span>
        <span
          v-if="items.length && !minLastDate && !lastCollection"
          class="text-grey fz-12"
          :class="{'text-hover-primary pointer': items.length}">
          ⚠️{{ lastDate }}
        </span>
      </span>
    </template>
    <v-sheet
      width="900"
      max-height="500"
      class="pa-1 scroll"
      style="box-shadow: 0px 8px 24px rgba(42, 53, 90, 0.1)">
      <p v-if="modalLoading" class="fz-14 text-center text-grey">Stat loading...</p>
      <v-data-table
        v-else
        v-model:options="modalOptions"
        hide-default-footer
        class="table-small"
        :headers="modalHeadersUrl"
        :items="items">
        <template #header.target="">
          <v-row class="ma-0 align-center flex-nowrap" style="max-height: 40px">
            <v-text-field
              v-model="searchObj.searchTarget"
              placeholder="Target"
              hide-details
              max-width="150"
              min-width="110"
              class="appBarTableInput"
              @click.stop>
              <template #prepend-inner="">
                <v-icon icon="$searchIcon" class="stroke-black" />
              </template>
            </v-text-field>
          </v-row>
        </template>
        <template #header.analyticCollection="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Analytic Collection</span>
          </v-row>
        </template>
        <template #header.contentLastUpdate="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap sortNone">
            <span class="fz-12 font-weight-medium text-nowrap">Content Last Update</span>
          </v-row>
        </template>
        <template #header.gscMaxDate="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">GSC Max Date</span>
          </v-row>
        </template>
        <template #header.keywordCollection="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Keyword Collection</span>
          </v-row>
        </template>
        <template #header.lactCheckIndexDate="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Last Check Index Date</span>
          </v-row>
        </template>
        <template #header.lastUpdate="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Last Update</span>
          </v-row>
        </template>
        <template #header.pageCollection="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Page Collection</span>
          </v-row>
        </template>
        <template #header.trafficCollection="">
          <v-row class="ma-0 flex-nowrap align-center flex-nowrap">
            <span class="fz-12 font-weight-medium text-nowrap">Traffic Collection</span>
          </v-row>
        </template>
        <template #item.target="{item}">
          <span class="text-no-wrap">{{ item.target }}</span>
        </template>
        <template #item.analyticCollection="{item}">
          <span class="text-no-wrap">
            {{ item.analyticCollection ? formatDate(item.analyticCollection, 'MD Time') : '---' }}
          </span>
        </template>
        <template #item.contentLastUpdate="{item}">
          <span class="text-no-wrap">
            {{ item.contentLastUpdate ? formatDate(item.contentLastUpdate, 'MD Time') : '---' }}
          </span>
        </template>
        <template #item.gscMaxDate="{item}">
          <span class="text-no-wrap">
            {{ item.gscMaxDate ? formatDate(item.gscMaxDate, 'MD Time') : '---' }}
          </span>
        </template>
        <template #item.keywordCollection="{item}">
          <span class="text-no-wrap">
            {{ item.keywordCollection ? formatDate(item.keywordCollection, 'MD Time') : '---' }}
          </span>
        </template>
        <template #item.lactCheckIndexDate="{item}">
          <span class="text-no-wrap">
            {{ item.lactCheckIndexDate ? formatDate(item.lactCheckIndexDate, 'MD Time') : '---' }}
          </span>
        </template>
        <template #item.lastUpdate="{item}">
          <span class="text-no-wrap">
            {{ item.lastUpdate ? formatDate(item.lastUpdate, 'MD Time') : '---' }}
          </span>
        </template>
        <template #item.pageCollection="{item}">
          <span class="text-no-wrap">
            {{ item.pageCollection ? formatDate(item.pageCollection, 'MD Time') : '---' }}
          </span>
        </template>
        <template #item.trafficCollection="{item}">
          <span class="text-no-wrap">
            {{ item.trafficCollection ? formatDate(item.trafficCollection, 'MD Time') : '---' }}
          </span>
        </template>
      </v-data-table>
    </v-sheet>
  </v-menu>
</template>
<script>
import formatDate from '@/helpers/formatDate';
import tableFrontSort  from '@/helpers/tableFrontSort';
export default {
  name: 'AppBarTableStat',
  props: ['items', 'minLastDate', 'lastCollection', 'searchObj'],
  data() {
    return {
      modalOptions: {
        sortBy: [],
      },
      modalLoading: false,
    };
  },
  computed: {
    lastDate() {
      return formatDate(
        this.items.find((item) => item.contentLastUpdate)?.contentLastUpdate || null,
        'MD Time'
      );
    },
    modalHeadersUrl() {
      return [
        {
          text: 'Target',
          value: 'target',
          sortable: true,
          headerProps: {
            class: this.tableFrontSort(this.modalOptions, 'target')
          },
        },
        {
          text: 'Analytic Collection',
          value: 'analyticCollection',
          sortable: true,
          headerProps: {
            class: this.tableFrontSort(this.modalOptions, 'analyticCollection')
          },
        },
        {
          text: 'Content Last Update',
          value: 'contentLastUpdate',
          sortable: true,
          headerProps: {
            class: this.tableFrontSort(this.modalOptions, 'contentLastUpdate')
          },
        },
        {
          text: 'GSC Max Date',
          value: 'gscMaxDate',
          sortable: true,
          headerProps: {
            class: this.tableFrontSort(this.modalOptions, 'gscMaxDate')
          },
        },
        {
          text: 'Keyword Collection',
          value: 'keywordCollection',
          sortable: true,
          headerProps: {
            class: this.tableFrontSort(this.modalOptions, 'keywordCollection')
          },
        },
        {
          text: 'Last Check Index Date',
          value: 'lactCheckIndexDate',
          sortable: true,
          headerProps: {
            class: this.tableFrontSort(this.modalOptions, 'lactCheckIndexDate')
          },
        },
        {
          text: 'Last Update',
          value: 'lastUpdate',
          sortable: true,
          headerProps: {
            class: this.tableFrontSort(this.modalOptions, 'lastUpdate')
          },
        },
        {
          text: 'Page Collection',
          value: 'pageCollection',
          sortable: true,
          headerProps: {
            class: this.tableFrontSort(this.modalOptions, 'pageCollection')
          },
        },
        {
          text: 'Traffic Collection',
          value: 'trafficCollection',
          sortable: true,
          headerProps: {
            class: this.tableFrontSort(this.modalOptions, 'trafficCollection')
          },
        },
      ];
    } 
  },
  methods: {
    formatDate,
    tableFrontSort,
  },
};
</script>

<style>
.appBarTableInput input {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #2a355a !important;
}
.appBarTableInput input::placeholder {
  color: #2a355a !important;
  opacity: 1 !important;
}
</style>
