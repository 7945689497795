<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6644 5.3289L8.66361 7.32973M10.6644 5.3289L8.66361 3.32806M10.6644 5.3289H7.99666C5.7866 5.3289 3.995 7.1205 3.995 9.33056M21.3355 26.6711L23.3364 24.6703M21.3355 26.6711L23.3364 28.672M21.3355 26.6711L24.0033 26.6711C26.2134 26.6711 28.005 24.8795 28.005 22.6695M14.7408 18.2983L12.1477 20.8914L10.5897 19.3347M23.7926 18.457C24.0508 18.2745 24.2947 18.0725 24.5222 17.8528C27.3754 14.9829 27.3754 10.3477 24.5222 7.47779C21.6524 4.62464 17.0171 4.62464 14.1472 7.47779C13.9273 7.70457 13.7232 7.94612 13.5363 8.20076M12.6653 26.6711C16.717 26.6711 20.0017 23.3865 20.0017 19.3347C20.0017 15.283 16.717 11.9983 12.6653 11.9983C8.6135 11.9983 5.32888 15.283 5.32888 19.3347C5.3522 23.3768 8.62319 26.6478 12.6653 26.6711Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'RlTop',
};
</script>

<style scoped></style>
