<template>
  <v-row class="header-container ma-0 align-center justify-space-between" style="padding: 0px 16px">
    <div :class="{'logo-hidden' : !drawer}" class="pa-0 logo" style="background-color: transparent; width: 216px">
      <v-icon
        style="stroke: none !important; max-height: 18px"
        size="102"
        icon="$Logo" />
    </div>
    <div :class="{'title-left' : !drawer}" class="title d-flex pointer" @click="$emit('showSidebar')">
      <v-app-bar-nav-icon style="width: 24px">
        <v-icon size="24">$burger</v-icon>
      </v-app-bar-nav-icon>
      <v-col class="ml-3 pa-0 d-flex flex-column justify-center">
        <span v-if="!isLostConnection" class="fz-14 font-weight-normal text-grey">
          {{ pageSubTitle ? pageSubTitle : pageTitle }}
        </span>
        <span v-else class="fz-14 lh-5 font-weight-normal" style="color: #D11149">
          Connection lost, try to update page, or wait for reconnect...
        </span>
      </v-col>
    </div>
    <v-spacer />
    <span class="header-table align-center mr-7">
      <v-icon
        v-if="items.length || minLastDate || lastCollection"
        size="14"
        class="mr-2 pointer"
        :class="{refreshSpin: loadingStat}"
        @click="getLastStat">
        $refreshData
      </v-icon>
      <AppBarTableStat
        :key="key"
        :min-last-date="minLastDate"
        :last-collection="lastCollection"
        :items="itemsArr"
        :search-obj="searchObj" />
    </span>
    <div class="user-info d-flex align-center">
      <v-menu offset="4" content-class="rounded-lg border-lightgray">
        <template #activator="{props}">
          <div class="ma-0 d-flex align-center pointer" v-bind="props">
            <v-avatar v-if="me" size="32" class="rounded-b-circle">
              <v-img v-if="me && me.avatar" :src="me.avatar" />
              <AvatarsItem v-else :item="me" custom-class="no-avatar-style_Item" />
            </v-avatar>
            <span class="mr-1 ml-2 fz-14 text-grey d-flex align-center">
              {{ me ? me.fullName : 'No name' }}
            </span>
            <v-sheet
              class="d-flex align-center btn-chevron-down chevron-item pointer"
              height="18"
              elevation="0"
              width="18"
              :class="{active: props['aria-expanded'] === 'true'}">
              <v-icon size="18">$chevronDown</v-icon>
            </v-sheet>
          </div>
        </template>
        <v-list max-width="300" width="300" class="mt-2">
          <v-card-title class="bb-gray pl-5 py-3 fz-10 text-black" style="border-bottom: 1px solid #e5e8ef">
            <div class="d-flex flex-row" style="width: 100%">
              <span class="fz-14 text-grey font-weight-light" style="width: 50%"> Team </span>
              <span class="fz-14 font-weight-light">
                {{ me && me.team && me.team.name }}
              </span>
            </div>
            <div v-if="me && me.teamRole && me.teamRole.name" class="d-flex flex-row" style="width: 100%">
              <span class="dropdown-key fz-14 text-grey font-weight-light" style="width: 50%"> Role </span>
              <span class="font-weight-light fz-14">
                {{ me && me.teamRole && me.teamRole.name }}
              </span>
            </div>
            <div v-if="me && formatBirthday" class="d-flex flex-row" style="width: 100%">
              <span class="font-weight-light fz-14 text-grey" style="width: 50%">Date of birth</span>
              <span class="font-weight-light fz-14">
                {{ me && formatBirthday[0] }}
              </span>
              <span class="text-grey fz-14 mx-1">/</span>
              <span class="font-weight-light fz-14">
                {{ me && formatBirthday[1] }}
              </span>
              <span class="text-grey fz-14 mx-1">/</span>
              <span class="font-weight-light fz-14">
                {{ me && formatBirthday[2] }}
              </span>
            </div>
          </v-card-title>
          <v-card-text class="px-5 pb-0">
            <v-list class="pa-0">
              <v-list-item v-if="me && me.email" class="pa-0 mt-3" style="min-height: 40px">
                <v-icon>$gmail</v-icon>
                <span class="ml-2">{{ me.email }}</span>
              </v-list-item>
              <v-list-item v-if="me && me.telegram" class="pa-0" style="min-height: 40px">
                <v-icon>$telegram</v-icon>
                <span class="ml-2">{{ me.telegram }}</span>
              </v-list-item>
              <v-list-item v-if="me && me.phone" class="pa-0 pb-3" style="min-height: 40px">
                <v-icon>$phone</v-icon>
                <span class="ml-2">{{ me && me.phone }}</span>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions
            class="px-5 py-2 list-item-hover pointer"
            style="border-top: 1px solid #e5e8ef"
            @click="logout">
            <v-icon>$logout</v-icon>
            <span class="ml-2 fz-14" style="color: #fc5a5a">Logout</span>
          </v-card-actions>
        </v-list>
      </v-menu>
    </div>
  </v-row>
</template>
<script>
import formatDate from '@/helpers/formatDate';
import AppBarTableStat from '@/components/AppBarTableStat';
import gql from 'graphql-tag';
import AvatarsItem from '@/components/md-ui/table/item/AvatarsItem';
export default {
  name: 'AppBar',
  components: {
    AppBarTableStat,
    AvatarsItem,
  },
  props: ['sidebarMini', 'me', 'isLostConnection', 'drawer'],
  emits: ['showSidebar'],
  data() {
    return {
      key: 0,
      renderButton: true,
      searchValue: '',
      searchShow: false,
      pageTitle: null,
      pageSubTitle: null,
      minLastDate: '',
      items: [],
      loadingStat: false,
      statName: null,
      lastCollection: '',
      searchObj: {
        searchTarget: '',
      },
    };
  },
  computed: {
    formatBirthday() {
      if (this.me.birthday) {
        return this.formatDate(this.me.birthday).split('.');
      } else return null;
    },
    globalLastStat() {
      return this.$store.state.globalLastStat;
    },
    itemsArr() {
      let arr = this.items;
      if (this.searchObj.searchTarget) {
        arr = arr.filter((el) => el.target.toLowerCase().includes(this.searchObj.searchTarget.toLowerCase()));
      }
      return arr;
    },
  },
  watch: {
    $route: {
      immediate: true, // Вызвать наблюдатель сразу после создания компонента
      handler(route) {
        this.pageTitle = route.name;
        let str = '';
        if (route.meta.path) {
          for (let i = 0; i < route.meta.path.length; i++) {
            if (i) str += ' / ';
            str += route.meta.path[i];
          }
        }
        if (route.name === 'By Project' || route.name === 'By Brand') {
          this.statName = 'RL';
          this.clearStat();
          this.getLastStat();
        } else this.statName = null;

        {
          this.pageSubTitle = str;
        }
        if (!route.path.includes('brief') || route.path.includes('brief/analytics')) {
          this.clearStat();
        }
      },
    },
    globalLastStat: {
      handler() {
        this.getLastStat();
      },
      deep: true,
    },
  },
  methods: {
    async logout() {
      await this.$apollo
        .mutate({
          mutation: gql`
            mutation logout {
              auth {
                logout
              }
            }
          `,
          fetchPolicy: 'no-cache',
        })
        .then(() => {
          this.$router.push('/login');
        });
    },
    formatDate,
    async getLastStat() {
      this.loadingStat = true;
      await this.$apollo
        .query({
          query: gql`
            query getLastStat($input: CollectionInfoInput!, $name: TaskNameEnum) {
              collection {
                info(input: $input) {
                  minLastDate
                  mostInfo {
                    analyticCollection
                    contentLastUpdate
                    keywordCollection
                    gscMaxDate
                    lactCheckIndexDate
                    lastUpdate
                    pageCollection
                    target
                    trafficCollection
                  }
                }
                lastCollection(name: $name)
              }
            }
          `,
          variables: {
            input: {
              categories:
                this.globalLastStat && this.globalLastStat.category ? this.globalLastStat.category : null,
              pageIds: null,
              pageUrls:
                this.globalLastStat && this.globalLastStat.pageUrls ? this.globalLastStat.pageUrls : null,
              projectDomains:
                this.globalLastStat && this.globalLastStat.projectDomains
                	? this.globalLastStat.projectDomains
                	: null,
              path: this.globalLastStat && this.globalLastStat.path ? this.globalLastStat.path : null,
              projectIds:
                this.globalLastStat && this.globalLastStat.projectIds.length
                	? this.globalLastStat.projectIds
                	: null,
            },
            name: this.statName,
          },
          fetchPolicy: 'no-cache',
        })
        .then((data) => {
          this.key++;
          this.loadingStat = false;
          this.minLastDate = data.data.collection.info.minLastDate
            ? formatDate(data.data.collection.info.minLastDate, 'MD Time')
            : null;
          this.lastCollection = data.data.collection.lastCollection
            ? formatDate(data.data.collection.lastCollection, 'MD Time')
            : null;
          this.items = data.data.collection.info.mostInfo;
        })
        .catch(() => {
          this.loadingStat = false;
        });
    },
    clearStat() {
      this.globalLastStat.projectIds = [];
      this.globalLastStat.pageUrls = [];
      this.globalLastStat.projectDomains = [];
      this.globalLastStat.path = '';
      this.globalLastStat.category = [];
      this.lastCollection = null;
      this.minLastDate = '';
      this.items = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  transform: translateX(0px);
  transition: transform 0.2s;
}
.logo-hidden {
  transform: translateX(-240px);
}
.title {
  transform: translateX(0px);
  transition: transform 0.2s;
}
.title-left {
  transform: translateX(-216px);
}
@media(max-width: 450px) {
    .header-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);

      .logo {
        width: max-content !important;
        grid-column: 1;
        grid-row: 1;
      }

      .logo-hidden {
        transform: translateX(0px);
      }

      .title {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row: 2;
      }

      .title-left {
        transform: translateX(0px);
      }

      .v-spacer {
        display: none;
      }

      .user-info {
        grid-column: 2;
        grid-row: 1;
      }

      .header-table {
        grid-column: 2;
        grid-row: 2;
      }
    }

  }
</style>
