<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect class="bg" x="0.5" y="0.5" width="23" height="23" rx="7.5" />
    <path class="ico" d="M8.66675 8.6665L15.3334 15.3332" stroke-linecap="round" stroke-linejoin="round" />
    <path class="ico" d="M15.3334 8.6665L8.66675 15.3332" stroke-linecap="round" stroke-linejoin="round" />
    <rect class="border" x="0.5" y="0.5" width="23" height="23" rx="7.5" />
  </svg>
</template>

<script>
export default {
  name: 'btnCancel',
};
</script>

<style scoped></style>
