<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5375 10.6486L16.4817 8.70441C17.9134 7.27274 17.9134 4.95107 16.4817 3.51857V3.51857C15.05 2.08691 12.7284 2.08691 11.2959 3.51857L9.35172 5.46274"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M7.40839 12.5915L12.5917 7.4082" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M5.4625 9.35156L3.51833 11.2957C2.08666 12.7274 2.08666 15.0491 3.51833 16.4816V16.4816C4.95 17.9132 7.27166 17.9132 8.70416 16.4816L10.6483 14.5374"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'LINK_NEW',
};
</script>

<style scoped></style>
