<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.75 7.49967H17.75" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.22492 10.8332H6.08325" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.25 15.8332H5.25C3.86917 15.8332 2.75 14.714 2.75 13.3332V6.6665C2.75 5.28567 3.86917 4.1665 5.25 4.1665H15.25C16.6308 4.1665 17.75 5.28567 17.75 6.6665V13.3332C17.75 14.714 16.6308 15.8332 15.25 15.8332Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'Card',
};
</script>

<style scoped></style>
