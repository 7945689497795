<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="#8F20EF" />
    <circle cx="10" cy="10" r="4" fill="white" />
  </svg>
</template>

<script>
export default {
  name: 'RadioOn',
};
</script>

<style scoped></style>
