<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.75 6V3.75C12.75 3.55109 12.671 3.36032 12.5303 3.21967C12.3897 3.07902 12.1989 3 12 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5M3 4.5C3 4.89782 3.15804 5.27936 3.43934 5.56066C3.72064 5.84196 4.10218 6 4.5 6H13.5C13.6989 6 13.8897 6.07902 14.0303 6.21967C14.171 6.36032 14.25 6.55109 14.25 6.75V9M3 4.5V13.5C3 13.8978 3.15804 14.2794 3.43934 14.5607C3.72064 14.842 4.10218 15 4.5 15H13.5C13.6989 15 13.8897 14.921 14.0303 14.7803C14.171 14.6397 14.25 14.4489 14.25 14.25V12M15 9V12H12C11.6022 12 11.2206 11.842 10.9393 11.5607C10.658 11.2794 10.5 10.8978 10.5 10.5C10.5 10.1022 10.658 9.72064 10.9393 9.43934C11.2206 9.15804 11.6022 9 12 9H15Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'menuFinance',
};
</script>

<style scoped></style>
