<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99749 14.5852C9.76823 14.5852 9.58065 14.7728 9.58315 15.002C9.58315 15.2313 9.77073 15.4189 10 15.4189C10.2293 15.4189 10.4168 15.2313 10.4168 15.002C10.4168 14.7728 10.2293 14.5852 9.99749 14.5852"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M9.99749 9.58313C9.76823 9.58313 9.58065 9.77071 9.58315 9.99997C9.58315 10.2292 9.77073 10.4168 10 10.4168C10.2293 10.4168 10.4168 10.2292 10.4168 9.99997C10.4168 9.77071 10.2293 9.58313 9.99749 9.58313"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M9.99749 4.58105C9.76823 4.58105 9.58065 4.76863 9.58315 4.9979C9.58315 5.22716 9.77073 5.41474 10 5.41474C10.2293 5.41474 10.4168 5.22716 10.4168 4.9979C10.4168 4.76863 10.2293 4.58105 9.99749 4.58105"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'dots',
};
</script>

<style scoped></style>
