<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 3.75C2.5 3.05964 3.05964 2.5 3.75 2.5H7.08333C7.77369 2.5 8.33333 3.05964 8.33333 3.75V7.08333C8.33333 7.77369 7.77369 8.33333 7.08333 8.33333H3.75C3.05964 8.33333 2.5 7.77369 2.5 7.08333V3.75Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6667 3.75C11.6667 3.05964 12.2264 2.5 12.9167 2.5H16.2501C16.9404 2.5 17.5001 3.05964 17.5001 3.75V7.08333C17.5001 7.77369 16.9404 8.33333 16.2501 8.33333H12.9167C12.2264 8.33333 11.6667 7.77369 11.6667 7.08333V3.75Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 12.9167C2.5 12.2263 3.05964 11.6667 3.75 11.6667H7.08333C7.77369 11.6667 8.33333 12.2263 8.33333 12.9167V16.25C8.33333 16.9404 7.77369 17.5 7.08333 17.5H3.75C3.05964 17.5 2.5 16.9404 2.5 16.25V12.9167Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6667 12.9167C11.6667 12.2263 12.2264 11.6667 12.9167 11.6667H16.2501C16.9404 11.6667 17.5001 12.2263 17.5001 12.9167V16.25C17.5001 16.9404 16.9404 17.5 16.2501 17.5H12.9167C12.2264 17.5 11.6667 16.9404 11.6667 16.25V12.9167Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'dashboardOpen',
};
</script>

<style scoped></style>
