<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 14.6666H28M6.66667 14.6666V9.33331C6.66667 8.27245 7.08809 7.25503 7.83824 6.50489C8.58839 5.75474 9.6058 5.33331 10.6667 5.33331H21.3333C22.3942 5.33331 23.4116 5.75474 24.1618 6.50489C24.9119 7.25503 25.3333 8.27245 25.3333 9.33331V14.6666M19.7767 26.6662L22.6061 26.6662C24.0494 26.688 25.3926 25.9305 26.1206 24.6841C26.4847 24.0607 26.6667 23.3635 26.6667 22.6662C26.6666 21.9692 26.4846 21.2723 26.1206 20.6492C25.3926 19.4027 24.0494 18.6453 22.6061 18.6671L17.9511 18.6671C16.8903 18.6667 15.8727 19.088 15.1226 19.8381C14.3725 20.5883 13.9512 21.6058 13.9516 22.6666L13.937 22.6667M17.9062 22.6662C17.9066 23.727 17.4854 24.7446 16.7352 25.4947C15.9851 26.2448 14.9676 26.6661 13.9067 26.6657H9.27278C7.0875 26.6326 5.33333 24.8517 5.33333 22.6662C5.33333 20.4807 7.0875 18.6997 9.27278 18.6666H11.2969" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BHLinks',
};
</script>

<style scoped>

</style>
