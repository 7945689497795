<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8333 9.16667V6.52333C15.8333 6.08167 15.6575 5.6575 15.345 5.345L12.9883 2.98833C12.6758 2.67583 12.2516 2.5 11.81 2.5H5.83329C4.91246 2.5 4.16663 3.24583 4.16663 4.16667V15.8333C4.16663 16.7542 4.91246 17.5 5.83329 17.5H9.16663"
      stroke="#FF974A"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M15.8333 6.66667H12.5C12.04 6.66667 11.6666 6.29333 11.6666 5.83333V2.5"
      stroke="#FF974A"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.2884 17.2558L17.255 14.2892C17.5809 13.9633 17.5809 13.4358 17.255 13.1108L15.9334 11.7892C15.6075 11.4633 15.08 11.4633 14.755 11.7892L11.7884 14.7558C11.6317 14.9125 11.5442 15.1242 11.5442 15.345V17.5H13.6992C13.92 17.5 14.1317 17.4125 14.2884 17.2558Z"
      stroke="#FF974A"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'updatedContent',
};
</script>

<style scoped></style>
