<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.3067 9.30666L28 12M25.3067 9.30666C26.8255 7.78778 26.825 5.3247 25.3061 3.80582C23.7873 2.28694 21.3247 2.28694 19.8058 3.80582C18.2869 5.3247 18.2869 7.78728 19.8058 9.30616C21.3247 10.825 23.7878 10.8255 25.3067 9.30666ZM14.6667 9.99999H5.33333C4.59733 9.99999 4 9.40266 4 8.66666V5.33332C4 4.59732 4.59733 3.99999 5.33333 3.99999H14.6667M12.296 17.3333H28M12.296 26H28M7 19.3333H5C4.448 19.3333 4 18.8853 4 18.3333V16.3333C4 15.7813 4.448 15.3333 5 15.3333H7C7.552 15.3333 8 15.7813 8 16.3333V18.3333C8 18.8853 7.552 19.3333 7 19.3333ZM7 28H5C4.448 28 4 27.552 4 27V25C4 24.448 4.448 24 5 24H7C7.552 24 8 24.448 8 25V27C8 27.552 7.552 28 7 28Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'URLProfiler',
};
</script>

<style scoped></style>
