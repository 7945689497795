<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3472 6.34306L13.9896 3.98541C13.677 3.6727 13.2529 3.49687 12.8108 3.49687H6.83161C5.91075 3.49687 5.16425 4.24337 5.16425 5.16423V16.8358C5.16425 17.7566 5.91075 18.5031 6.83161 18.5031H15.1684C16.0893 18.5031 16.8358 17.7566 16.8358 16.8358V7.52188C16.8358 7.07971 16.6599 6.65568 16.3472 6.34306Z"
      stroke="#24C182"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M13.4999 10.1667L10.3749 13.2917L8.49994 11.4167"
      stroke="#24C182"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'itemComplete',
};
</script>

<style scoped></style>
