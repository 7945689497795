<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.345 5.345L12.9883 2.98833C12.6758 2.67583 12.2516 2.5 11.81 2.5H5.83329C4.91246 2.5 4.16663 3.24583 4.16663 4.16667V15.8333C4.16663 16.7542 4.91246 17.5 5.83329 17.5H14.1666C15.0875 17.5 15.8333 16.7542 15.8333 15.8333V6.52333C15.8333 6.08167 15.6575 5.6575 15.345 5.345V5.345Z"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M15.8333 6.66667H12.5C12.04 6.66667 11.6666 6.29333 11.6666 5.83333V2.5"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M12.6624 15C12.5332 14.675 12.3324 14.3825 12.0757 14.145V14.145C11.6407 13.7425 11.0707 13.5183 10.4782 13.5183H9.52157C8.92907 13.5183 8.35824 13.7425 7.92407 14.145V14.145C7.66657 14.3825 7.46657 14.675 7.3374 15"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M10.9575 9.22417C11.4863 9.75301 11.4863 10.6104 10.9575 11.1393C10.4286 11.6681 9.57121 11.6681 9.04238 11.1393C8.51354 10.6104 8.51354 9.753 9.04238 9.22417C9.57121 8.69534 10.4286 8.69534 10.9575 9.22417"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'loggedUsers',
};
</script>

<style scoped></style>
