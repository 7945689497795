<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" fill="#F2F6FA" />
    <path
      d="M9.33337 15.9217V12.7425C9.33337 11.7025 9.97754 10.7708 10.95 10.4033L17.9109 7.775C19.0017 7.36417 20.1667 8.16917 20.1667 9.335V12.1267"
      stroke="#4075A3"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M20.3742 17.7508C20.2592 17.7517 20.1667 17.845 20.1667 17.96C20.1667 18.075 20.26 18.1683 20.375 18.1675C20.49 18.1675 20.5834 18.0742 20.5834 17.9592C20.5834 17.8442 20.49 17.7508 20.3742 17.7508"
      stroke="#4075A3"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M14.885 23.5H21C22.375 23.5 23.5 22.375 23.5 21V14.6258C23.5 13.245 22.3809 12.1258 21 12.1258H11C10.0792 12.1258 9.33337 12.8717 9.33337 13.7925V16.2142"
      stroke="#4075A3"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M12.25 24.3333C9.71913 24.3333 7.66663 22.2808 7.66663 19.75C7.66663 17.2192 9.71913 15.1667 12.25 15.1667C14.7816 15.1667 16.8333 17.2192 16.8333 19.75C16.8333 22.2808 14.7816 24.3333 12.25 24.3333"
      stroke="#4075A3"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M14.0775 19.75H10.4225" stroke="#4075A3" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'CPSpending',
};
</script>

<style scoped></style>
