<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" rx="9" fill="#F9E2E9"/>
    <path d="M11.5 6.5L6.5 11.5M6.5 6.5L11.5 11.5" stroke="#D11149" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'rejected',
};
</script>

<style scoped></style>
