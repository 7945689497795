import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { createApolloProvider } from '@vue/apollo-option';
import { onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client/utilities';
import { RateLimitLink } from './rate-limit-link';
import store from '@/store';

function isSerializable(obj) {
  try {
    JSON.stringify(obj);
    return true;
  } catch (e) {
    return false;
  }
}

function hasAuthCookies() {
  return document.cookie.includes('csrftoken') || window.location.origin.includes('localhost');
}

// Middleware
const authMiddleware = new ApolloLink((operation, forward) => {
  if (!hasAuthCookies()) {
    window.location.href = '/login';
    return new Observable((observer) => {
      observer.error(new Error('Unauthorized: No valid authentication token'));
    });
  }
  return forward(operation);
});

// Middleware
const validationMiddleware = new ApolloLink((operation, forward) => {
  const { query, variables, operationName } = operation;

  if (!query?.loc?.source?.body) {
    return new Observable((observer) => {
      observer.error(new Error('Invalid query: query is missing or malformed'));
    });
  }

  if (variables !== undefined && !isSerializable(variables)) {
    return new Observable((observer) => {
      observer.error(new Error('Invalid variables: contains non-serializable data'));
    });
  }

  const context = operation.getContext();
  let requestBody;

  if (context.hasUpload || context.body instanceof FormData) {
    const body = context.body instanceof FormData ? context.body : new FormData();
    if (!body.has('operations') || !body.has('map')) {
      return new Observable((observer) => {
        observer.error(new Error('Invalid FormData: missing "operations" or "map"'));
      });
    }
    try {
      JSON.parse(body.get('operations'));
    } catch {
      return new Observable((observer) => {
        observer.error(new Error('Invalid FormData: "operations" is not valid JSON'));
      });
    }
    requestBody = body;
  } else {
    requestBody = {
      query: query.loc.source.body,
      variables: variables || {},
      operationName: operationName || null,
    };

    if (!isSerializable(requestBody)) {
      return new Observable((observer) => {
        observer.error(new Error('Invalid request body: cannot serialize to JSON'));
      });
    }
  }

  operation.setContext({ ...context, body: requestBody });
  return forward(operation);
});

// Error handling
const errorLink = onError(({ networkError, operation, forward }) => {
  if (networkError?.statusCode === 401) {
    window.location.href = '/login';
    return;
  }

  if ([502, 503].includes(networkError?.statusCode) || networkError?.message === 'Failed to fetch') {
    return new Observable((observer) => {
      setTimeout(() => {
        forward(operation).subscribe(observer);
      }, 5000);
    });
  }
});

const uploadLink = createUploadLink({
  uri: process.env.VUE_APP_SERVER_URL
    ? `${process.env.VUE_APP_SERVER_URL}/v2/graphql/`
    : 'https://marketing-desk-test.ddns.net/backend/v2/graphql/',
  credentials: 'include',
  fetch: async (uri, options) => {
    if (!hasAuthCookies()) {
      throw new Error('Unauthorized: No valid authentication token');
    }

    const body = options.body;
    if (body instanceof FormData) {
      if (!body.has('operations') || !body.has('map')) {
        throw new Error('FormData is missing "operations" or "map" field!');
      }
      try {
        JSON.parse(body.get('operations'));
      } catch {
        throw new Error('FormData "operations" is not valid JSON');
      }
    } else if (typeof body === 'string') {
      try {
        JSON.parse(body);
      } catch {
        throw new Error('Request body is not valid JSON');
      }
    } else {
      throw new Error('Request body must be FormData or JSON string');
    }

    return fetch(uri, options);
  },
});

const rateLimitLink = new RateLimitLink(30);

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    authMiddleware,
    validationMiddleware,
    errorLink,
    rateLimitLink,
    uploadLink,
  ]),
  cache: new InMemoryCache(),
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

export const baseApiUrl = process.env.VUE_APP_SERVER_URL;
export { apolloClient, apolloProvider };
store.commit('setApolloClient', apolloClient);
export default apolloProvider;
