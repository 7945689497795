<template>
  <section>
    <v-snackbar
      v-for="(message, index) in messages"
      :key="index"
      v-model="message.snackbar"
      min-width="320"
      color="#fff"
      :timeout="5000"
      :style="snackbarStyle(index)"
      @update:modelValue="onCloseSnack(index)">
      <v-sheet class="d-flex">
        <span>
          <v-row class="fz-14 font-weight-medium text-black lh-5 ma-0 mb-2 align-center">
            <v-icon size="24" class="mr-2">$snack{{ message.icon }}</v-icon>
            {{ message.title ? message.title : message.result }}
          </v-row>
          <p class="ml-8">{{ message.text }}</p>
        </span>
        <v-btn
          elevation="0"
          height="20"
          width="20"
          class="pa-0 ml-auto"
          style="border-radius: 6px; background: #f5f7fb; min-width: 20px"
          :ripple="false"
          @click="closeSnack(index, message)">
          <v-icon size="20">$close</v-icon>
        </v-btn>
      </v-sheet>
    </v-snackbar>
  </section>
</template>
<script>
export default {
  name: 'MdSnackbars',
  props: ['messages'],
  data() {
    return {
      previousMessages: [],
      removeTimers: {},
    };
  },
  watch: {
    messages: {
      handler(newMessages) {
        this.handleMessages(newMessages);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleMessages(newMessages) {
      newMessages.forEach((message, index) => {
        if (!this.removeTimers[index]) {
          if (typeof message.snackbar === 'undefined') {
            message.snackbar = true;
          }

          this.removeTimers[index] = setTimeout(() => {
            this.onCloseSnack(index);
          }, 5000);
        }
      });

      this.previousMessages = [...newMessages];
    },
    closeSnack(index, message) {
      message.snackbar = false;
      clearTimeout(this.removeTimers[index]);
      delete this.removeTimers[index];
      this.messages.splice(index, 1);
    },
    onCloseSnack(index) {
      clearTimeout(this.removeTimers[index]);
      delete this.removeTimers[index];
      this.messages.splice(index, 1);
    },
    snackbarStyle(index) {
      return {
        '--snackbar-index': index,
      };
    },
  },
};
</script>

<style lang="scss">
.v-overlay__content.v-snackbar__wrapper.v-theme--light.v-snackbar--variant-elevated {
  position: absolute !important;
  display: block !important;
  left: 0 !important;
  bottom: calc(var(--snackbar-index) * 90px);
  margin-bottom: 10px !important;
  width: max-content !important;
}
</style>
