<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7833 9.21667L4.8916 15.1083"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M5.52722 10.8325L2.64138 10.1508C2.32388 10.0758 2.21222 9.68166 2.44222 9.45083L4.73722 7.15583C4.89138 7.00166 5.10055 6.91333 5.31972 6.91166L7.90722 6.88916"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5656 7.47918L17.2389 4.23918C17.4222 3.35752 16.6422 2.57752 15.7606 2.76085L12.5206 3.43418C11.5664 3.63252 10.6914 4.10502 10.0031 4.79418L7.34473 7.45168C6.33057 8.46585 5.67057 9.78085 5.46223 11.2L5.45307 11.2608C5.3214 12.1667 5.62473 13.0808 6.2714 13.7283V13.7283C6.91807 14.375 7.83307 14.6783 8.7389 14.5458L8.79973 14.5367C10.2189 14.3292 11.5339 13.6683 12.5481 12.6542L15.2056 9.99668C15.8947 9.30835 16.3672 8.43335 16.5656 7.47918V7.47918Z"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M11.167 3.92834C13.0928 5.24168 14.7587 6.90751 16.072 8.83335"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M9.16699 14.4725L9.84866 17.3584C9.92366 17.6759 10.3178 17.7875 10.5487 17.5575L12.8437 15.2625C12.9978 15.1084 13.0862 14.8992 13.0878 14.68L13.1103 12.0925"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'brefProjectStart',
};
</script>

<style scoped></style>
