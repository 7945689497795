import { gql } from '@apollo/client/core';

const actions = {
  async saveFilter({ rootState }, { $apollo, tab, name, comment, queryOptions }) {
    const filterData = JSON.stringify(queryOptions);
    return await $apollo.mutate({
      mutation: gql`
        mutation saveFilter($input: CustomFilterInput!) {
          utils {
            customFilters {
              create(input: $input) {
                id
                data
                description
                name
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          tab,
          name,
          description: comment,
          data: filterData,
        },
      },
    });
  },

  async getSavedFilter({ rootState }, { $apollo, tab }) {
    const { data } = await $apollo.query({
      query: gql`
        query getSavedFilter($tab: String!) {
          utils {
            customFilters {
              customFilters(tab: $tab) {
                id
                data
                name
                description
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { tab },
    });
    return data.utils.customFilters.customFilters;
  },

  async deleteFilter({ rootState }, { $apollo, id }) {
    return await $apollo.mutate({
      mutation: gql`
        mutation deleteFilter($id: ID!) {
          utils {
            customFilters {
              delete(id: $id)
            }
          }
        }
      `,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
  },

  async updateFilter({ rootState }, { $apollo, tab, item }) {
    const filterData = JSON.stringify(item.data);
    return await $apollo.mutate({
      mutation: gql`
        mutation updateFilter($input: CustomFilterInput!, $id: ID!) {
          utils {
            customFilters {
              update(input: $input, id: $id) {
                name
                description
                tab
                data
              }
            }
          }
        }
      `,
      variables: {
        id: item.id,
        input: {
          tab,
          name: item.name || '',
          description: item.description || '',
          data: filterData,
        },
      },
      fetchPolicy: 'no-cache',
    });
  },
};

export default {
  namespaced: true,
  actions,
};