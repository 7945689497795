<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="18" viewBox="0 0 60 18" fill="none">
    <path
      d="M54.4056 8.73298H52.3386C51.0034 8.73298 50.2715 9.73188 50.2715 11.4627V17.6638H46.2759V8.73298H44.6045C43.2693 8.73298 42.5374 9.73188 42.5374 11.4627V17.6638H38.5418V5.40001H42.5374V7.26924C43.2001 5.8055 44.0704 5.40001 45.4056 5.40001H50.2715V7.26924C50.9342 5.8055 51.8045 5.40001 53.1397 5.40001H54.4748L54.4056 8.73298ZM37.6023 12.4714H29.3341C29.5319 13.8758 30.4023 14.6077 31.7968 14.6077C32.8649 14.6077 33.5968 14.1429 33.8638 13.411L37.3946 14.4099C36.5242 16.5462 34.3979 17.8121 31.7968 17.8121C27.4649 17.8121 25.4671 14.4099 25.4671 11.4824C25.4671 8.6143 27.1979 5.2121 31.5297 5.2121C36.1286 5.2121 37.6616 8.68353 37.6616 11.2747C37.6715 11.8682 37.6715 12.2044 37.6023 12.4714ZM33.8045 10.1374C33.7352 9.06925 32.9341 8.13957 31.6089 8.13957C30.3429 8.13957 29.611 8.67364 29.344 10.1374H33.8045ZM18.5341 17.6638H22.0055L26.4066 5.45935H22.4011L20.2649 12.5901L18.1286 5.39012H14.133L18.5341 17.6638ZM2.26484 17.6638H6.20111V8.73298H9.99892V17.6638H13.9352V5.40001H6.20111V4.66814C6.20111 3.86704 6.80441 3.33297 7.66485 3.33297H9.99892V0H7.07144C4.2033 0 2.27473 1.8 2.27473 4.40111V5.40001H0V8.73298H2.26484V17.6638Z"
      fill="#404145" />
    <path
      d="M56.878 18C58.2132 18 59.3407 16.8627 59.3407 15.5374C59.3407 14.2022 58.2033 13.0748 56.878 13.0748C55.5527 13.0748 54.4153 14.2121 54.4153 15.5374C54.4055 16.8726 55.5428 18 56.878 18Z"
      fill="#1DBF73" />
  </svg>
</template>

<script>
export default {
  name: 'fiverrLogo',
};
</script>

<style scoped></style>
