<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3744 18.2322 7.62562 18.2322 4.6967 15.3033C1.76777 12.3744 1.76777 7.62562 4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M4.01333 14.49C3.06833 13.235 2.5 11.6825 2.5 10C2.5 5.875 5.875 2.5 10 2.5"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="0" />
    <path d="M9.79167 5.83334V6.87501" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.79167 14.1667V13.125" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M11.8642 8.33333C11.81 7.52 11.1392 6.875 10.3125 6.875H9.17083C8.36249 6.875 7.70833 7.53 7.70833 8.3375C7.70833 9.00833 8.16499 9.59333 8.81499 9.75667L10.7675 10.2467C11.4183 10.41 11.8742 10.995 11.8742 11.6658C11.8742 12.4742 11.2192 13.1283 10.4117 13.1283H9.26999C8.44166 13.1283 7.77083 12.4817 7.71749 11.6667"
      stroke="#8F20EF"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'itemDollar',
};
</script>

<style scoped></style>
