<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 4.75C3.5 4.05964 4.05964 3.5 4.75 3.5H8.08333C8.77369 3.5 9.33333 4.05964 9.33333 4.75V8.08333C9.33333 8.77369 8.77369 9.33333 8.08333 9.33333H4.75C4.05964 9.33333 3.5 8.77369 3.5 8.08333V4.75Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6665 4.75C12.6665 4.05964 13.2261 3.5 13.9165 3.5H17.2498C17.9402 3.5 18.4998 4.05964 18.4998 4.75V8.08333C18.4998 8.77369 17.9402 9.33333 17.2498 9.33333H13.9165C13.2261 9.33333 12.6665 8.77369 12.6665 8.08333V4.75Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 13.9167C3.5 13.2263 4.05964 12.6667 4.75 12.6667H8.08333C8.77369 12.6667 9.33333 13.2263 9.33333 13.9167V17.25C9.33333 17.9404 8.77369 18.5 8.08333 18.5H4.75C4.05964 18.5 3.5 17.9404 3.5 17.25V13.9167Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6665 13.9167C12.6665 13.2263 13.2261 12.6667 13.9165 12.6667H17.2498C17.9402 12.6667 18.4998 13.2263 18.4998 13.9167V17.25C18.4998 17.9404 17.9402 18.5 17.2498 18.5H13.9165C13.2261 18.5 12.6665 17.9404 12.6665 17.25V13.9167Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M20 2L2 20" stroke="#F5F7FB" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M20 2L2 20" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'dashboardClose',
};
</script>

<style scoped></style>
