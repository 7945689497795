import { gql } from '@apollo/client/core';

const CANCEL_PAYMENT = gql`
  mutation cancelPayment($orderId: ID!) {
    finance {
      cancelOrder(orderId: $orderId) {
        code
        comment
      }
    }
  }
`;

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async cancelPayment({ rootState }, { orderId }) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await rootState.apolloClient.mutate({
          mutation: CANCEL_PAYMENT,
          variables: { orderId },
          fetchPolicy: 'no-cache',
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },
};