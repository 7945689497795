import { gql } from 'graphql-tag';
export async function getFeatureFlag(featureName, $apollo) {
  try {
    const { data } = await $apollo.query({
      query: gql`
        query getFeatureFlag($input: String!) {
          utils {
            featureFlags {
              featureFlag(name: $input) {
                featureIsActive
              }
            }
          }
        }
      `,
      variables: {
        input: featureName,
      },
      fetchPolicy: 'no-cache',
    });
    return data.utils.featureFlags.featureFlag.featureIsActive;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
