<template>
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 5C9 5.39782 9.15804 5.77936 9.43934 6.06066C9.72064 6.34196 10.1022 6.5 10.5 6.5C10.8978 6.5 11.2794 6.34196 11.5607 6.06066C11.842 5.77936 12 5.39782 12 5M9 5C9 4.60218 9.15804 4.22064 9.43934 3.93934C9.72064 3.65804 10.1022 3.5 10.5 3.5C10.8978 3.5 11.2794 3.65804 11.5607 3.93934C11.842 4.22064 12 4.60218 12 5M9 5H3M12 5H15M4.5 9.5C4.5 9.89782 4.65804 10.2794 4.93934 10.5607C5.22064 10.842 5.60218 11 6 11C6.39782 11 6.77936 10.842 7.06066 10.5607C7.34196 10.2794 7.5 9.89782 7.5 9.5M4.5 9.5C4.5 9.10218 4.65804 8.72064 4.93934 8.43934C5.22064 8.15804 5.60218 8 6 8C6.39782 8 6.77936 8.15804 7.06066 8.43934C7.34196 8.72064 7.5 9.10218 7.5 9.5M4.5 9.5H3M7.5 9.5H15M11.25 14C11.25 14.3978 11.408 14.7794 11.6893 15.0607C11.9706 15.342 12.3522 15.5 12.75 15.5C13.1478 15.5 13.5294 15.342 13.8107 15.0607C14.092 14.7794 14.25 14.3978 14.25 14M11.25 14C11.25 13.6022 11.408 13.2206 11.6893 12.9393C11.9706 12.658 12.3522 12.5 12.75 12.5C13.1478 12.5 13.5294 12.658 13.8107 12.9393C14.092 13.2206 14.25 13.6022 14.25 14M11.25 14H3M14.25 14H15" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'settings',
};
</script>

<style scoped></style>
