<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6934 6.74667L9.25342 4.30667" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.16733 14H2V11.8327C2 11.656 2.07 11.486 2.19533 11.3613L11.0847 2.47132C11.3453 2.21065 11.7673 2.21065 12.0273 2.47132L13.528 3.97198C13.7887 4.23265 13.7887 4.65465 13.528 4.91465L4.63867 13.8047C4.514 13.93 4.344 14 4.16733 14V14Z"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'edit',
};
</script>

<style scoped></style>
