<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8333 9.16667V6.52333C15.8333 6.08167 15.6575 5.6575 15.345 5.345L12.9883 2.98833C12.6758 2.67583 12.2517 2.5 11.81 2.5H5.83332C4.91249 2.5 4.16666 3.24583 4.16666 4.16667V15.8333C4.16666 16.7542 4.91249 17.5 5.83332 17.5H9.16666"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M15.8333 6.66667H12.5C12.04 6.66667 11.6667 6.29333 11.6667 5.83333V2.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.2884 17.256L17.2551 14.2893C17.5809 13.9635 17.5809 13.436 17.2551 13.111L15.9334 11.7893C15.6076 11.4635 15.0801 11.4635 14.7551 11.7893L11.7884 14.756C11.6317 14.9126 11.5442 15.1243 11.5442 15.3451V17.5001H13.6992C13.9201 17.5001 14.1317 17.4126 14.2884 17.256Z"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'CONTENT_UPDATE',
};
</script>

<style scoped></style>
