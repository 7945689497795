import {createStore} from 'vuex';
import {monetizationModule} from '@/store/modules/monetizationModule';
import financeModule from '@/store/modules/finance';
import customFilters from '@/store/modules/customFilters';

const store = createStore({
  modules: {
    monetization: monetizationModule,
    finance: financeModule,
    customFilters: customFilters,
  },
  state: {
    apolloClient: null,
    messages: [],
    allProjectsTech: [],
    orderTypes: [
      {
        name: '1. Submit Links',
        value: 'SUBMIT_LINKS',
        permissionName: 'readFinanceOrdersSubmitLinks',
        permissionValue: null,
      },
      {
        name: '2. Content Deploy',
        value: 'CONTENT_DEPLOY',
        permissionName: 'readFinanceOrdersContentDeploy',
        permissionValue: null,
      },
      {
        name: '3. Crowd Links',
        value: 'CROWD_LINKS',
        permissionName: 'readFinanceOrdersCrowdLinks',
        permissionValue: null,
      },
      {
        name: '4. Content',
        value: 'CONTENT',
        permissionName: 'readFinanceOrdersContent',
        permissionValue: null,
      },
      {
        name: '5. Design',
        value: 'DESIGN',
        permissionName: 'readFinanceOrdersDesign',
        permissionValue: null,
      },
      {
        name: '6. Development',
        value: 'DEVELOPMENT',
        permissionName: 'readFinanceOrdersDevelopment',
        permissionValue: null,
      },
      {
        name: '7. Docs',
        value: 'DOCS',
        permissionName: 'readFinanceOrdersDocs',
        permissionValue: null,
      },
      {
        name: '8. Domain',
        value: 'DOMAIN',
        permissionName: 'readFinanceOrdersDomain',
        permissionValue: null,
      },
      {
        name: '9. Hosting',
        value: 'HOSTING',
        permissionName: 'readFinanceOrdersHosting',
        permissionValue: null,
      },
      {
        name: '10. Images',
        value: 'IMAGES',
        permissionName: 'readFinanceOrdersImages',
        permissionValue: null,
      },
      {
        name: '11. Infrastructure',
        value: 'INFRASTRUCTURE',
        permissionName: 'readFinanceOrdersInfrastructure',
        permissionValue: null,
      },
      {
        name: '12. Tools',
        value: 'TOOLS',
        permissionName: 'readFinanceOrdersTools',
        permissionValue: null,
      },
      {
        name: '13. Outreach Links',
        value: 'OUTREACH_LINKS',
        permissionName: 'readFinanceOrdersOutreachLinks',
        permissionValue: null,
      },
      {
        name: '14. Media materials',
        value: 'MEDIA_MATERIALS',
        permissionName: 'readFinanceOrdersMediaMaterials',
        permissionValue: null,
      },
    ],
    paymentTypes: [
      {
        name: 'USDT TRC20',
        value: 'USDT_TRC20',
      },
      {
        name: 'USDT ERC20',
        value: 'USDT_ERC20',
      },
      {
        name: 'USDC ERC20',
        value: 'USDC_ERC20',
      },
      {
        name: 'Card RUS',
        value: 'CARD_RUS',
      },
      {
        name: 'Card UA',
        value: 'CARD_UA',
      },
      {
        name: 'BTC',
        value: 'BTC',
      },
      {
        name: 'ETH',
        value: 'ETH',
      },
      {
        name: 'Fiverr',
        value: 'FIVERR',
      },
      {
        name: 'PayPal',
        value: 'PAYPAL',
      },
    ],
    selectedTool: {
      selectedDomain: null,
      charts: null,
      allCountries: null,
      selectedCountry: null,
    },
    snackbarTimeout: 5000,
    me: JSON.parse(localStorage.getItem('userInfo')) || null,
    triggerMonitoringTimeout: 5000,
    permissions: null,
    tooltips: [
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        '12': '12',
        '13': '13',
        '14': '14',
        '15': '15',
        '16': '16',
        '17': 'Crash Team Racing',
        '18': '18',
        '19': '19',
        '20': '20',
        '21': '21',
        '22': '22',
        '23': '23',
        '24': '24',
        '25': '25',
        'Location': 'OverviewTable'
      },
      {
        '1': 'traffic on main country compare with last month',
        '2': 'keywords on main country compare with last month',
        '3': 'total number of links growth over the last month',
        '4': 'total number of content growth over the last month',
        '5': 'all costs',
        '6': 'income on this month compare with last month',
        '7': 'project profit',
        'Location': 'BrefCards'
      },
      {
        '1': 'operation',
        '2': 'position',
        '3': 'total keyword',
        '4': 'main keyword',
        'Location': 'ProfilerCards'
      },
      {
        '1': 'Operation Type',
        '2': 'Main keyword',
        '3': 'anchor type',
        '4': 'rank',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        '12': 'Order',
        '13': 'Details',
        'Location': 'ProfilerTable'
      },
      {
        '1': 'all traffic on all projects in current month, num of projects and num of regions',
        '2': 'total number of links growth over the last month',
        '3': 'total number of new content growth over the last month',
        '4': 'total number of updated content growth over the last month',
        '5': '5',
        'Location': 'InsightsCards'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        'Location': 'ContentItemsCards'
      },
      {
        '1': '1',
        '2': '2',
        'Location': 'CopywritersCards'
      },
      {
        '1': '44504',
        'Location': 'TeamCards'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': 'Ahrefs Traffic',
        '7': '7',
        '8': '8',
        '9': '9',
        'Location': 'ReuseCheckerTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': 'average surferseo score',
        '4': '\nrating for the current order',
        '5': '5',
        '6': 'number of urls in order',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        'Location': 'ContentItemsTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        'Location': 'ContentItemsEditItemTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        '12': '12',
        '13': '13',
        '14': '14',
        'Location': 'CopywritersTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        'Location': 'TeamTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': 'no sort',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        '12': '12',
        '13': '13',
        '14': '14',
        '15': '15',
        '16': '16',
        '17': 'no sort',
        '18': 'no sort',
        '19': 'no sort',
        '20': 'no sort',
        '21': 'no sort',
        '22': 'no sort',
        '23': '23',
        '24': '24',
        '25': '25',
        '26': '26',
        '27': '27',
        'Location': 'LinkbuildingTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        '12': '12',
        '13': '13',
        '14': '14',
        '15': '15',
        'Location': 'LBOutsourcersTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        'Location': 'RelatedContentTable'
      },
      {
        '1': '1',
        '2': '2',
        'Location': 'BrefDateStart'
      },
      {
        '1': 'no sort',
        '2': 'Rank Change',
        '3': 'Clicks Change',
        '4': 'Impressions Change',
        '5': 'CTR Change',
        '6': 'Position Change',
        '7': 'URLs Change',
        'Location': 'KeywordCheckerTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        '12': '12',
        '13': '13',
        '14': '14',
        '15': '15',
        'Location': 'MaintenanceProjectsTable'
      },
      {
        '1': 'no sort',
        'Location': 'MaintenanceHostingsTable'
      },
      {
        '1': 'no sort',
        'Location': 'MaintenanceProjectManagmentTable'
      },
      {
        '1': 'url',
        '2': 'page.page.url',
        '3': 'keyword',
        '4': 'cost',
        '5': 'category',
        '6': 'copywriter.name',
        '7': 'clicks',
        '8': 'wordsCount',
        '9': 'projectEarnings',
        '10': 'page.clicks',
        '11': 'copywriter.rank',
        '12': 'copywriter.priceWithCommission',
        '13': 'copywriter.writingSpeed',
        '14': 'position',
        '15': 'page.position',
        '16': 'impressions',
        '17': 'page.impressions',
        '18': 'keywords',
        '19': 'page.keywords',
        '20': 'created',
        '21': 'updated',
        '22': 'urls',
        '23': 'refPage',
        '24': 'rd',
        '25': 'page.rd',
        '26': 'totalCost',
        '27': 'dr',
        '28': 'tf',
        '29': 'tfUrl',
        '30': 'ahrefsPositions',
        'Location': 'BrefSmallTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        'Location': 'AllStatsTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        'Location': 'AllStatsTableItem'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        'Location': 'AllStatsTableBrand'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        'Location': 'AllStatsTableItemBrand'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        'Location': 'AnchorAnalyzerCards'
      },
      {
        '1': '1',
        '2': 'no sort',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        'Location': 'AnchorAnalyzerAnchorTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        'Location': 'AnchorAnalyzerRefDomainTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        'Location': 'AnchorAnalyzerKeywordsTable'
      },
      {

        '1': 'RD Change',
        '2': 'Rank Change',
        '3': 'Clicks Change',
        '4': 'Impressions Change',
        '5': 'CTR Change',
        '6': 'Position Change',
        '7': 'Keyword Change',
        '8': 'no sort',
        '9': 'NDC Change',
        '10': 'REGS Change',
        'Location': 'TopPagesTable'
      },
      {
        '1': 'no sort',
        'Location': 'ContentGapTable'
      },
      {
        '1': 'no sort',
        'Location': 'CrossmapTables'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        'Location': 'FinanceTasksTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        'Location': 'FinanceTasksStats'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        'Location': 'MyCardsTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        'Location': 'OutreachBaseCards'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        'Location': 'ContentPlanCards'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        '12': '12',
        '13': '13',
        '14': '14',
        '15': '15',
        '16': '16',
        '17': '17',
        '18': '18',
        'Location': 'ContentPlanTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        'Location': 'LinkBuildingPlanCards'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        'Location': 'PBNPlanCards'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        '12': '12',
        '13': '13',
        '14': '14',
        '15': 'no sort',
        'Location': 'PBNPlanTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        '12': '12',
        '13': '13',
        '14': '14',
        '15': '15',
        'Location': 'SemanticsTable'
      },
      {
        '1': '1',
        'Location': 'OrderItemsCards'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        'Location': 'OrderItemsTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        'Location': 'SuppliersTable'
      },
      {
        '1': '1',
        'Location': 'SupppliersCards'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        '12': '12',
        '13': '13',
        'Location': 'ToolsTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        'Location': 'GscTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        'Location': 'GscTableGroups'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        'Location': 'EmailsTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        'Location': 'ReportsTable'
      },
      {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        'Location': 'LinkPBNTable'
      },
      {
        '1': 'no sort',
        'Location': 'LogsTable'
      }
    ],
    christmasMode: localStorage.getItem('md_christmas'),
    allProjects: [],
    allProjectsPbn: [],
    allRegions: [],
    allLang: null,
    allLanguages: [],
    allCountries: [],
    siteTypes: [],
    currentStage: [],
    projectStatuses: [],
    domainTypes: [],
    portalThemes: [],
    allUsers: [],
    domainPortal: null,
    sendToIndexOpen: false,
    speedtest: null,
    top3chart: true,
    speedresult: 0,
    checkIndexing: null,
    switchProject: false,
    chosenRegions: [],
    globalLastStat: {
      projectIds: [],
      path: '',
      category: '',
      projectDomains: [],
      pageUrls: [],
    },
    globalRedirectProject: [],
    globalRedirectInfo: [],
    globalLoadingRedirect: false,
    globalRedirectRegion: [],
    drawer: true,
  },
  mutations: {
    setApolloClient(state, client) {
      state.apolloClient = client;
    },
    addMessage(state, message) {
      state.messages.push(message);
    },
    updateOrderTypes(state) {
      state.orderTypes.forEach((item) => {
        const permissionPath = item.permissionName.split('.');
        item.permissionValue = permissionPath.reduce((acc, el) => acc?.[el], state?.me?.tabPermissions);
      });
      state.orderTypes = state.orderTypes.filter((item) => item.permissionValue === true);
    },
    updateChosenRegions(state, chosenRegions) {
      state.chosenRegions = chosenRegions;
    },
    updateLoadingRedirect(state, globalLoadingRedirect) {
      state.globalLoadingRedirect = globalLoadingRedirect;
    },
    updateRedirectInfo(state, globalRedirectInfo) {
      state.globalRedirectInfo = [];
      if (state.chosenRegions.length && globalRedirectInfo.length) {
        state.globalRedirectInfo.push({redirectedFrom: state.chosenRegions.reduce((acc, region) => {
          return acc = acc.concat(globalRedirectInfo[0].redirectedFrom.filter((item) => item.region.id == region));
        }, [])});
      } else {
        state.globalRedirectInfo = globalRedirectInfo;
      }
    },
    updateRedirectProject(state, globalRedirectProject) {
      state.globalRedirectProject = [];
      typeof globalRedirectProject === 'number' ?
        state.globalRedirectProject = globalRedirectProject :
        state.globalRedirectProject = state.allProjects.find((e) => e.domain === globalRedirectProject)?.id;
    },
    updateRedirectRegion(state, globalRedirectRegion) {
      state.globalRedirectRegion = [];
      state.globalRedirectRegion = globalRedirectRegion;
    },
    updateGlobalProjectStat(state, globalProjectStat) {
      state.globalLastStat.projectIds = [];
      state.globalLastStat.projectIds = globalProjectStat;
    },
    updateGlobalPathStat(state, globalPathStat) {
      state.globalLastStat.path = '';
      state.globalLastStat.path = globalPathStat;
    },
    updateGlobalCategoryStat(state, globalCategoryStat) {
      state.globalLastStat.category = [];
      state.globalLastStat.category = globalCategoryStat;
    },
    updateGlobalProjectDomainsStat(state, globalProjectDomains) {
      state.globalLastStat.projectDomains = [];
      state.globalLastStat.projectDomains = globalProjectDomains;
    },
    updateGlobalPageUrlsStat(state, globalPageUrls) {
      state.globalLastStat.pageUrls = [];
      state.globalLastStat.pageUrls = globalPageUrls;
    },
    clearCheck(state) {
      clearTimeout(state.checkIndexing);
    },
    updateChristmasMode(state, christmasMode) {
      state.christmasMode = christmasMode;
    },
    updateSwitchProject(state, switchProject) {
      state.switchProject = switchProject;
    },
    updateCheckIndexing(state, checkIndexing) {
      state.checkIndexing = checkIndexing;
    },
    updateTop3Chart(state, top3chart) {
      state.top3chart = top3chart;
    },
    updatePermissions(state, permissions) {
      state.permissions = permissions;
    },
    updateSpeedResult(state, speedresult) {
      state.speedresult = speedresult;
    },
    updateSpeedTest(state, speedtest) {
      state.speedtest = speedtest;
    },
    updateSendToIndexOpen(state, sendToIndexOpen) {
      state.sendToIndexOpen = sendToIndexOpen;
    },
    updateDomainPortal(state, domainPortal) {
      state.domainPortal = domainPortal;
    },
    updateSelectedTool(state, selectedTool) {
      state.selectedTool = selectedTool;
    },
    updateMe(state, me) {
      state.me = me;
    },
    updateAllProjects(state, allProjects) {
      state.allProjects = allProjects;
    },
    updateAllProjectsPbn(state, allProjects) {
      state.allProjectsPbn = allProjects;
    },
    updateAllProjectsTech(state, allProjectsTech) {
      state.allProjectsTech = allProjectsTech;
    },
    updateAllRegions(state, allRegions) {
      state.allRegions = allRegions;
    },
    updateAllLanguages(state, allLanguages) {
      state.allLanguages = allLanguages;
    },
    updateAllCountries(state, allCountries) {
      state.allCountries = allCountries;
    },
    updateSiteTypes(state, siteTypes) {
      state.siteTypes = siteTypes;
    },
    updateCurrentStage(state, currentStage) {
      state.currentStage = currentStage;
    },
    updateProjectStatuses(state, projectStatuses) {
      state.projectStatuses = projectStatuses;
    },
    updateDomainTypes(state, domainTypes) {
      state.domainTypes = domainTypes;
    },
    updatePortalThemes(state, portalThemes) {
      state.portalThemes = portalThemes;
    },
    updateAllUsers(state, allUsers) {
      state.allUsers = allUsers;
    },
    updateDrawer(state) {
      state.drawer = !state.drawer;
    },
  },
  getters: {
    getGlobalLastStat(state) {
      return state.globalLastStat;
    },
    getAllProjectsTech(state) {
      return state.allProjectsTech;
    },
    getAllProjectsPbn(state) {
      return state.allProjectsTech;
    },
    getOrderTypes(state) {
      return state.orderTypes;
    },
    getPaymentTypes(state) {
      return state.paymentTypes;
    },
    getChristmasMode(state) {
      return state.christmasMode;
    },
    getSnackbarTimeout(state) {
      return state.snackbarTimeout;
    },
    getSwitchProject(state) {
      return state.switchProject;
    },
    getCheckIndexing(state) {
      return state.checkIndexing;
    },
    getTop3Chart(state) {
      return state.top3chart;
    },
    getPermissions(state) {
      return state.permissions;
    },
    getSpeedResult(state) {
      return state.speedresult;
    },
    getSpeedtest(state) {
      return state.speedtest;
    },
    getSendToIndexOpen(state) {
      return state.sendToIndexOpen;
    },
    getDomainPortal(state) {
      return state.domainPortal;
    },
    getSelectedTool(state) {
      const tool = state.selectedTool;
      if (tool.charts && tool.charts.trafficByCategory) {
        tool.charts.trafficByCategory.forEach((el) => {
          if (el.category) el.category.value = true;
        });
      }
      return tool;
    },
    getMe(state) {
      return state.me;
    },
    getTooltips(state) {
      return state.tooltips;
    },
    getAllProjects(state) {
      return state.allProjects;
    },
    getAllRegions(state) {
      return state.allRegions;
    },
    getAllLanguages(state) {
      return state.allLanguages;
    },
    getAllCountries(state) {
      return state.allCountries;
    },
    getSiteTypes(state) {
      return state.siteTypes;
    },
    getCurrentStage(state) {
      return state.currentStage;
    },
    getProjectStatuses(state) {
      return state.projectStatuses;
    },
    getDomainTypes(state) {
      return state.domainTypes;
    },
    getPortalThemes(state) {
      return state.portalThemes;
    },
    getAllUsers(state) {
      return state.allUsers;
    },
    getRedirectProjects(state) {
      return state.globalRedirectProject;
    },
    getRedirectRegions(state) {
      return state.globalRedirectRegion;
    },
    getRedirectInfo(state) {
      return state.globalRedirectInfo;
    },
    getLoadingRedirect(state) {
      return state.globalLoadingRedirect;
    },
  },
});

export default store;
