<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75018 11.25L11.2502 6.74998M8.25018 4.49999L8.59743 4.09799C9.30079 3.39473 10.2547 2.99969 11.2493 2.99976C12.2439 2.99983 13.1978 3.39501 13.9011 4.09836C14.6043 4.80171 14.9994 5.75563 14.9993 6.75025C14.9992 7.74487 14.604 8.69873 13.9007 9.40199L13.5002 9.74999M9.75011 13.5L9.45236 13.9005C8.74079 14.6041 7.78045 14.9988 6.77973 14.9988C5.77902 14.9988 4.81867 14.6041 4.10711 13.9005C3.75637 13.5537 3.47793 13.1407 3.28789 12.6856C3.09785 12.2304 3 11.7421 3 11.2489C3 10.7556 3.09785 10.2673 3.28789 9.81213C3.47793 9.35697 3.75637 8.94403 4.10711 8.59723L4.50011 8.24998" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'menuLinkBuilding',
};
</script>

<style scoped></style>
