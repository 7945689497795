<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.04646 10.9533C8.07146 9.97831 7.33646 8.88664 6.84813 7.77914C6.7448 7.54497 6.80563 7.27081 6.98646 7.08997L7.66896 6.40831C8.22813 5.84914 8.22813 5.05831 7.7398 4.56997L6.76146 3.59164C6.11063 2.94081 5.05563 2.94081 4.4048 3.59164L3.86146 4.13497C3.24396 4.75247 2.98646 5.64331 3.15313 6.52664C3.5648 8.70414 4.8298 11.0883 6.87063 13.1291C8.91146 15.17 11.2956 16.435 13.4731 16.8466C14.3565 17.0133 15.2473 16.7558 15.8648 16.1383L16.4073 15.5958C17.0581 14.945 17.0581 13.89 16.4073 13.2391L15.4298 12.2616C14.9415 11.7733 14.1498 11.7733 13.6623 12.2616L12.9098 13.015C12.729 13.1958 12.4548 13.2566 12.2206 13.1533C11.1131 12.6641 10.0215 11.9283 9.04646 10.9533Z"
      stroke="#8591AE"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'phone',
};
</script>

<style scoped></style>
