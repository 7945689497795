<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0586 19.1206C15.9588 19.1206 19.1206 15.9588 19.1206 12.0586C19.1206 8.15841 15.9588 4.99667 12.0586 4.99667C8.15841 4.99667 4.99667 8.15841 4.99667 12.0586C4.99667 15.9588 8.15841 19.1206 12.0586 19.1206Z"
      stroke="#010820"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M21.0033 21.0033L17.0517 17.0517"
      stroke="#010820"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'searchDark',
};
</script>
