<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4817 15.6037C10.2165 15.8542 9.78354 15.8542 9.51834 15.6037L6.44738 12.7038C6.02385 12.3039 6.32697 11.6249 6.92905 11.6249L13.071 11.6249C13.673 11.6249 13.9761 12.3039 13.5526 12.7038L10.4817 15.6037Z"
      fill="#D3D3D7" />
    <path
      d="M9.51833 5.18782C9.78354 4.93738 10.2165 4.93738 10.4817 5.18782L13.5526 8.08772C13.9761 8.48766 13.673 9.16666 13.071 9.16666L6.92905 9.16666C6.32697 9.16666 6.02385 8.48766 6.44738 8.08772L9.51833 5.18782Z"
      fill="#242436" />
  </svg>
</template>

<script>
export default {
  name: 'tableSortUp',
};
</script>

<style scoped></style>
