// src/rate-limit-link.js
import { ApolloLink } from '@apollo/client/core';
import { Observable } from '@apollo/client/utilities';

export class RateLimitLink extends ApolloLink {
  constructor(limit = 30) { //RPS Limit
    super();
    this.limit = limit;
    this.requestCount = 0;
    this.resetTime = Date.now();
    this.queue = [];
    this.isProcessing = false;
  }

  request(operation, forward) {
    const now = Date.now();
    const secondsElapsed = (now - this.resetTime) / 1000;

    if (secondsElapsed >= 1) {
      this.requestCount = 0;
      this.resetTime = now;
      this.processQueue(forward);
    }

    if (this.requestCount < this.limit) {
      this.requestCount += 1;
      return forward(operation);
    }

    return new Observable((observer) => {
      this.queue.push({ operation, forward, observer });
      this.processQueue(forward);
    });
  }

  processQueue(forward) {
    if (this.isProcessing) return;
    this.isProcessing = true;

    const processNextBatch = () => {
      const now = Date.now();
      const timeUntilNextSecond = 1000 - (now - this.resetTime);

      if (this.requestCount >= this.limit && timeUntilNextSecond > 0) {
        setTimeout(() => {
          this.requestCount = 0;
          this.resetTime = Date.now();
          processNextBatch();
        }, timeUntilNextSecond);
        return;
      }

      while (this.queue.length > 0 && this.requestCount < this.limit) {
        const { operation, forward, observer } = this.queue.shift();
        this.requestCount += 1;

        const subscription = forward(operation).subscribe({
          next: (result) => observer.next(result),
          error: (error) => observer.error(error),
          complete: () => observer.complete(),
        });

        observer._subscription = subscription;
      }

      if (this.queue.length > 0) {
        const nextTick = 1000 - (Date.now() - this.resetTime);
        setTimeout(() => {
          this.requestCount = 0;
          this.resetTime = Date.now();
          processNextBatch();
        }, nextTick > 0 ? nextTick : 1000);
      } else {
        this.isProcessing = false;
      }
    };

    processNextBatch();
  }
}