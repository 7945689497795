<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20L20 12M14.6667 7.99999L15.284 7.28532C16.5344 6.03509 18.2303 5.33279 19.9985 5.33292C21.7667 5.33304 23.4624 6.03558 24.7127 7.28599C25.9629 8.5364 26.6652 10.2322 26.6651 12.0005C26.665 13.7687 25.9624 15.4644 24.712 16.7147L24 17.3333M17.3332 24L16.8039 24.712C15.5389 25.9629 13.8316 26.6645 12.0525 26.6645C10.2735 26.6645 8.5662 25.9629 7.3012 24.712C6.67767 24.0955 6.18266 23.3614 5.84481 22.5522C5.50697 21.743 5.33301 20.8749 5.33301 19.998C5.33301 19.1211 5.50697 18.253 5.84481 17.4438C6.18266 16.6346 6.67767 15.9005 7.3012 15.284L7.99986 14.6667" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Backlink',
};
</script>

<style scoped></style>
