<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.6667 4V9.33333C18.6667 9.68696 18.8072 10.0261 19.0572 10.2761C19.3073 10.5262 19.6464 10.6667 20 10.6667H25.3334M18.6667 4H9.33335C8.62611 4 7.94783 4.28095 7.44774 4.78105C6.94764 5.28115 6.66669 5.95942 6.66669 6.66667V25.3333C6.66669 26.0406 6.94764 26.7189 7.44774 27.219C7.94783 27.719 8.62611 28 9.33335 28H22.6667C23.3739 28 24.0522 27.719 24.5523 27.219C25.0524 26.7189 25.3334 26.0406 25.3334 25.3333V10.6667M18.6667 4L25.3334 10.6667M12 22.6667H20M12 17.3333H20" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ProjectContent',
};
</script>

<style scoped></style>
