<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38 20V18C38 15.79 36.21 14 34 14H25.07C24.402 14 23.776 13.666 23.406 13.11L20.594 8.892C20.222 8.334 19.598 8 18.93 8H10C7.79 8 6 9.79 6 12V37.706C6 38.974 7.026 40 8.294 40V40C9.306 40 10.2 39.336 10.49 38.366L15.144 22.852C15.652 21.158 17.21 20 18.976 20H38.658C41.328 20 43.248 22.564 42.496 25.126L38.974 37.126C38.474 38.83 36.912 40 35.136 40H8.294"
      stroke="#8591AE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'GroupIcon',
};
</script>

<style scoped></style>
